// Libraries import
import React from "react";
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";

// Styling import
import "./App.scss";
import "react-toastify/dist/ReactToastify.css";

// Components import/
import Main from "./components/Main/Main";
import Policy from "./components/Policy/Policy";
import Wallet from "./components/Wallet/Wallet";
import HowTo from "./components/HowTo/HowTo";
import Terms from "./components/Terms/Terms";

const App = () => {
    return (
        <div className="App">
            <ToastContainer />
            <Router>
                <Routes>
                    <Route path="/" element={<Main />} />
                    <Route path="/policy" element={<Policy />} />
                    <Route path="/wallet" element={<Wallet />} />
                    <Route path="/how" element={<HowTo />} />
                    <Route path="/terms" element={<Terms />} />
                    <Route path="*" element={<Navigate to="/" replace />} />
                </Routes>
            </Router>
        </div>
    );
};

export default App;
