// Libraries import
import React, { useEffect } from "react";

// Styling import
import "./Terms.scss";
import { Link } from "react-router-dom";

// Components import

const Terms = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="Terms">
            <p id="header" className="main-heading">
                Elementals website terms of use
            </p>
            <div className="container">
                <div className="context">
                    <p id="content">
                        Welcome to our Website Terms of Use{" "}
                        <b>(Website T&Cs)</b>. These Website T&Cs apply to all
                        visitors to the Elementals website,{" "}
                        <Link
                            id="link"
                            to={"https://elementalsnfts.com/"}
                            target="_blank"
                        >
                            https://elementalsnfts.com/
                        </Link>{" "}
                        <b>(Website)</b> and to all purchasers of Elementals
                        NFTs on our website.
                        <br />
                        <br />
                        These Website T&Cs were last updated on 18 May 2023. We
                        may update the Website T&Cs from time to time and will
                        post any revised versions on this web page.
                        <br />
                        <br />
                        It is important to us that we have an open and
                        transparent relationship with all of our users. If you
                        have any questions or would like further information
                        about our Website T&Cs you can get in touch with us by
                        emailing us at support@elementalsnfts.com.
                        <br />
                        <br />
                        <br />
                    </p>
                    <ol>
                        <p id="header">Elementals Basics</p>
                        <li id="heading">Who are we?</li>
                        <p id="content">
                            <br />
                            In these Website T&Cs, ‘us’, ‘we’, ‘our’ and
                            ‘Elementals’ are used to collectively refer to
                            astrologer Jessica Adams (ACN 55 851 319 538),
                            artist and designer Anita Bacic (ABN 17 405 461 579)
                            and artist Tintin Cooper, jointly and severally.
                            <br />
                            <br />
                        </p>
                        <li id="heading">What do we do?</li>
                        <p id="content">
                            <br />
                            We have collaborated on the design, build and sale
                            of Elementals: Astrology NFTs.
                            <br />
                            <br />
                            While we facilitate the sale of Elementals NFTs, we
                            are a non-custodial service provider. This means
                            that we do not have custody, possession or control
                            of Elementals NFTs, cryptocurrency or fiat currency
                            at any time for the purpose of facilitating the sale
                            of Elementals NFTs.
                            <br />
                            <br />
                        </p>
                        <li id="heading">
                            What are Elementals: Astrology NFTs?
                        </li>
                        <p id="content">
                            <br />
                            Elementals: Astrology NFTs are 256 unique
                            Ethereum-based cryptographic tokens (
                            <b>Elementals NFTs</b>). We use the ERC-721 smart
                            contract specification for the Elementals NFTs smart
                            contract, which runs the minting, sale and purchase
                            of Elementals NFTs on Avalanche, a layer 2 Ethereum
                            solution.
                            <br />
                            <br />
                            We reserve the right in our sole and absolute
                            discretion to amend, modify, alter or supplement the
                            Elementals NFTs smart contract from time to time.
                            <br />
                            <br />
                            Each Elementals NFT features an original digital
                            artwork created by Tintin Cooper and Anita Bacic.
                            The file of the original digital artwork is stored
                            on the Interplanetary File System, a peer-to-peer
                            network for storing and sharing data in a
                            distributed file system (<b>IPFS</b>). The
                            Elementals NFTs smart contract includes the IPFS
                            hash (<b>Original Digital Artwork</b>).
                            <br />
                            <br />
                            Every owner of one or more NFTs from Elementals NFTs
                            who supplies her or his email address, will be
                            invited to one of four exclusive one-hour Zoom
                            events on the weekend of October 7th and 8th 2023.
                            These will be recorded for their access for a
                            limited time.
                            <br />
                            <br />
                            The four events are part of a series called ‘In Your
                            Element in 2024’ where Jessica Adams helps the Fire,
                            Earth, Air and Water element sign groups to use
                            astrology timing for the year ahead.
                            <br />
                            <br />
                            FIRE Sun sign Aries, Leo, Sagittarius
                            <br />
                            EARTH Sun sign Taurus, Virgo, Capricorn
                            <br />
                            AIR Sun sign Gemini, Libra, Aquarius
                            <br />
                            WATER Sun sign Cancer, Scorpio, Pisces
                            <br />
                            <br />
                        </p>
                        <li id="heading">
                            Risks of blockchain transactions and digital assets
                        </li>
                        <p id="content">
                            <br />
                            <b>You acknowledge the risks of smart contracts</b>
                            <br />
                            <br />
                            All transactions using Elementals NFTs, including,
                            but not limited to, minting and purchasing
                            Elementals NFTs are done at your sole discretion and
                            at your complete risk. If you invoke, call, request
                            or otherwise engage with an Elementals NFT smart
                            contract you agree to be bound by the outcome of
                            that Elementals NFT smart contract, whether or not
                            the Elementals NFT smart contract behaves as you
                            expect.
                            <br />
                            <br />
                            We do not own nor control third party digital
                            wallets, Google Chrome, the Ethereum or Avalanche
                            networks, or any other third-party site, product or
                            service that you might access, visit or use for the
                            purpose of using our Website or minting, purchasing
                            or selling Elementals NFTs.
                            <br />
                            <br />
                            You acknowledge that Elementals NFTs may be subject
                            to bugs, malfunctions, timing errors, hacking and
                            theft, or changes to the protocol rules of the
                            Ethereum or Avalanche blockchains (i.e., "forks"),
                            which can adversely affect the Elementals NFTs and
                            may expose you to a risk of total loss, forfeiture
                            of your cryptocurrency or Elementals NFTs, or lost
                            opportunities to buy or sell Elementals NFTs.
                            <br />
                            <br />
                            You assume the risk of initiating, interacting with,
                            and participating in the minting and sale of
                            Elementals NFTs and take full responsibility and
                            liability for the outcome of any transactions you
                            initiate, interact with or participate in, whether
                            or not the Elementals NFTs, our Website, or other
                            users behave as expected or intended.
                            <br />
                            <br />
                            We will not be liable for the acts or omissions of
                            any third parties, or your use of third party sites,
                            products or services, including digital wallet
                            providers, Google Chrome, the Ethereum network or
                            Avalanche networks, or any other third party, nor
                            will we be liable to you or any third party for any
                            direct or indirect damage or loss that you may
                            suffer as a result of using our Website or minting,
                            purchasing or selling Elementals NFTs.
                            <br />
                            <br />
                            <b>You acknowledge the risks of our Website</b>
                            <br />
                            <br />
                            You acknowledge that our Website is subject to flaws
                            and acknowledge that you are solely responsible for
                            evaluating any code provided by us. This warning and
                            others provided in these Website T&Cs is in no way
                            evidence or a representation of an ongoing duty to
                            alert you to all of the potential risks of utilising
                            or accessing our Website and minting, purchasing or
                            selling Elementals NFTs.
                            <br />
                            <br />
                            Our Website may experience sophisticated
                            cyber-attacks, unexpected surges in activity, or
                            other operational or technical difficulties that may
                            cause interruptions to or delays on the Website or
                            to the transfer of Elementals NFTs.
                            <br />
                            <br />
                            You agree to accept the risk of our Website failing
                            resulting from unanticipated or heightened technical
                            difficulties, including those resulting from
                            sophisticated attacks, and you agree not to hold us
                            accountable for any related losses. We will not
                            bear any liability, whatsoever, for any damage or
                            interruptions caused by any viruses that may affect
                            your computer or other equipment, or any phishing,
                            spoofing, or other attacks.
                            <br />
                            <br />
                            <b>You acknowledge the risks of digital assets</b>
                            <br />
                            <br />
                            The prices of digital assets are extremely volatile.
                            Fluctuations in the price of other digital assets
                            could materially and adversely affect the value of
                            your Elementals NFTs, which may also be subject to
                            significant price volatility.
                            <br />
                            <br />
                            Digital assets are, by their nature highly
                            experimental, risky and volatile. Smart contract
                            transactions carried out using our Website are
                            irreversible, final and non-refundable. 
                            <br />
                            <br />
                            You acknowledge and agree that you will access and
                            use the Elementals NFTs at your own risk. The risk
                            of loss in trading digital assets can be
                            substantial. You should therefore carefully
                            consider whether buying or selling digital assets is
                            suitable for you in light of your circumstances and
                            financial resources.
                            <br />
                            <br />
                            By using our Website, you represent that you have
                            been, are, and will be solely responsible for making
                            your own independent appraisal and investigations
                            into the risks of a given transaction and the
                            underlying digital assets. You represent that you
                            have sufficient knowledge, market sophistication,
                            professional advice, and experience to make your own
                            evaluation of the merits and risks of any
                            transaction conducted via our Website or any
                            underlying digital asset.
                            <br />
                            <br />
                            You accept all consequences of using our Website,
                            including the risk that you may lose access to your
                            digital assets indefinitely. All transaction
                            decisions are made solely by you. Notwithstanding
                            anything in these Website T&Cs, we accept no
                            responsibility whatsoever for, and will in no
                            circumstances be liable to you in connection with
                            the use of our Elementals NFTs smart contracts for
                            performing digital asset transactions. Under no
                            circumstances will the providing of our Website, or
                            the operation of the Elementals NFTs, be deemed to
                            create a relationship that includes the provision or
                            tendering of investment advice.
                            <br />
                            <br />
                        </p>
                        <li id="heading">Additional terms and conditions</li>
                        <p id="content">
                            <br />
                            For certain services or activities relating to
                            Elementals NFTs additional terms and conditions may
                            apply. In case of any inconsistency between such
                            additional terms and conditions and these Website
                            T&Cs, those additional terms and conditions will
                            prevail.
                            <br />
                            <br />
                            <br />
                        </p>
                        <p id="header">ALL ABOUT YOU</p>
                        <li id="heading">Your personal information</li>
                        <p id="content">
                            <br />
                            We may collect, hold, use, and disclose personal
                            information about you when you access or use the
                            content or services on our Website, for purposes
                            which are directly related to our services and
                            activities. Such services and activities include,
                            for example, registering your digital wallet’s
                            address on our Website for an early access discount.
                            <br />
                            <br />
                            Further details about our practices relating to the
                            collection, use, disclosure and storage of your
                            personal information are included in our{" "}
                            <Link id="link" to={"/policy"}>
                                Privacy Policy
                            </Link>
                            .
                            <br />
                            <br />
                        </p>
                        <li id="heading">Elementals NFTs registration</li>
                        <p id="content">
                            <br />
                            You will need to register your digital wallet’s
                            address on our Website prior to 19th May 2023 in
                            order to receive a 15% discount on the purchase of
                            Elementals NFTs from our Website.
                            <br />
                            <br />
                            In order to register, your digital wallet address
                            must be connected to a digital wallet provider that
                            is compatible with our Website. Currently, the
                            digital wallet browser that is compatible with our
                            Website is MetaMask (
                            <b>Compatible Digital Wallet</b>). We may add or
                            remove Compatible Digital Wallets time to time.
                            <br />
                            <br />
                            If you do not provide an accurate and complete
                            address for your Compatible Digital Wallet we may
                            not be able to activate your discount for your
                            purchase(s) of Elementals NFTs on our Website.
                            <br />
                            <br />
                        </p>
                        <li id="heading">Redeeming astrology sessions</li>
                        <p id="content">
                            <br />
                            Every owner of one or more NFTs from Elementals NFTs
                            who supplies her or his email address, will be
                            invited to one of four exclusive one-hour Zoom
                            events on the weekend of October 7th and 8th 2023.
                            These will be recorded for their access for a
                            limited time.
                            <br />
                            <br />
                            The four events are part of a series called ‘In Your
                            Element in 2024’ where Jessica Adams helps the Fire,
                            Earth, Air and Water element sign groups to use
                            astrology timing for the year ahead.
                            <br />
                            <br />
                            FIRE Sun sign Aries, Leo, Sagittarius
                            <br />
                            EARTH Sun sign Taurus, Virgo, Capricorn
                            <br />
                            AIR Sun sign Gemini, Libra, Aquarius
                            <br />
                            WATER Sun sign Cancer, Scorpio, Pisces
                            <br />
                            <br />
                            You will be able to join a session through Zoom. Be
                            sure to follow our announcements on Twitter at:{" "}
                            <Link
                                id="link"
                                to={"https://twitter.com/AstroElementals"}
                                target="_blank"
                            >
                                https://twitter.com/AstroElementals
                            </Link>
                            . Alternatively, if you want to be reminded by
                            email, message us at info@elementalsnfts.com or DM
                            us on Twitter at:{" "}
                            <Link
                                id="link"
                                to={"https://twitter.com/AstroElementals"}
                                target="_blank"
                            >
                                https://twitter.com/AstroElementals
                            </Link>
                            .
                            <br />
                            <br />
                            You will be able to redeem your session by sending
                            us your wallet address and image of your NFT (via
                            Twitter message or email).
                            <br />
                            <br />
                        </p>
                        <li id="heading">Your privacy and security</li>
                        <p id="content">
                            <br />
                            You are responsible for protecting your privacy when
                            using our Website and purchasing Elementals NFTs. 
                            <br />
                            <br />
                            For security, technical, maintenance, legal or
                            regulatory reasons, or due to any breach of these
                            Website T&Cs, we may impose limits or restrictions
                            on the use you may make of our Website, withdraw the
                            Website, or change or remove Website functionality
                            at any time without notice to you.
                            <br />
                            <br />
                            We will not be liable to you or any third party for
                            any direct or indirect losses (including loss of
                            profits, business or opportunities), damages or
                            costs suffered by you or any other person or entity
                            due to any such suspension or restriction of access
                            to our Website.
                            <br />
                            <br />
                        </p>
                        <li id="heading">Legal capacity</li>
                        <p id="content">
                            <br />
                            You must be eighteen (18) years of age or over to
                            register for a 15% discount on the purchase of
                            Elementals NFTs as well as to purchase Elementals
                            NFTs using our Website.
                            <br />
                            <br />
                            Any purchase made by you using this Website is an
                            acknowledgement by you that you are over the age of
                            eighteen (18) years, you accept these Website T&Cs
                            and agree that you have entered into a legal
                            contract with us in relation to these terms.
                            <br />
                            <br />
                            We reserve the right to take legal action and seek
                            compensation for any loss or damage we may suffer as
                            a result of a transaction entered into by a minor,
                            from the parent or guardian of such minor.
                            <br />
                            <br />
                            <br />
                        </p>
                        <p id="header">PURCHASING ELEMENTALS</p>
                        <li id="heading">Sale disclaimers</li>
                        <p id="content">
                            <br />
                            <b>
                                We reserve the right to purchase or withdraw
                                Elemental NFTs from sale
                            </b>
                            <br />
                            <br />
                            We reserve the right to mint or execute the
                            Elementals NFTs smart contract as a purchaser of
                            Elementals NFTs.
                            <br />
                            <br />
                            We also reserve the right to withdraw the sale of
                            Elementals NFTs on our Website.
                            <br />
                            <br />
                            <b>
                                We make no representations or warranties about
                                Elementals NFTs
                            </b>
                            <br />
                            <br />
                            Features and specifications of Elementals NFTs
                            described or depicted on our Website are subject to
                            change without notice.
                            <br />
                            <br />
                            We make no representations or warranties, express or
                            implied, written or oral, made by or on behalf of us
                            in connection therewith, including any
                            representations or warranties of title,
                            non-infringement, functionality, merchantability,
                            usage, security, suitability or fitness for any
                            particular purpose, workmanship or technical quality
                            of any Elementals NFT, Original Digital Artwork or
                            software.
                            <br />
                            <br />
                            <b>
                                We are not liable for the outcome of any
                                transaction of Elementals NFTs
                            </b>
                            <br />
                            <br />
                            We shall not be liable to you for contract, tort, or
                            any other types of damages, including indirect,
                            special, incidental, consequential, punitive or
                            exemplary damages arising out of or related to your
                            participation in or the outcome of a transaction
                            involving an Elementals NFT, whether or not we have
                            been advised or knew of the possibility of such
                            damages.
                            <br />
                            <br />
                            Our liability for damages under these Website T&Cs
                            shall in all cases be limited to, and under no
                            circumstances shall exceed, the amount of proceeds
                            actually received by us collectively, arising out of
                            the damage after deducting all costs, fees, and
                            liabilities incurred by or on behalf of us.
                            <br />
                            <br />
                        </p>
                        <li id="heading">Prices</li>
                        <p id="content">
                            <br />
                            All prices listed on our Website:
                            <br />
                            <br />
                        </p>
                        <ul style={{ listStyle: "disc" }}>
                            <li>
                                <p id="content">
                                    Are in AVAX, Avalanche’s native token, if
                                    they are in listed cryptocurrency;
                                </p>
                            </li>
                            <li>
                                <p id="content">
                                    Do not include any applicable taxes, gas
                                    fees or additional charges; and
                                </p>
                            </li>
                            <li>
                                <p id="content">
                                    Are subject to change without notice.
                                </p>
                            </li>
                        </ul>
                        <p id="content">
                            <br />
                            Promotional discount codes may be given at our sole
                            discretion on the terms and conditions notified at
                            the time of the relevant promotion.
                            <br />
                            <br />
                            We do not represent, guarantee, or warrant the
                            accuracy or fairness of the price of any Elementals
                            NFT sold or offered for sale outside of our
                            Website.
                            <br />
                            <br />
                            You acknowledge and agree that we are not a
                            fiduciary nor do we owe any duties to you, including
                            the duty to ensure fair pricing of Elementals NFTs.
                            <br />
                            <br />
                        </p>
                        <li id="heading">Resale royalties</li>
                        <p id="content">
                            <br />
                            <b>Resale royalties</b>
                            <br />
                            <br />
                            For every secondary sale of Elementals NFTs, 2.5% of
                            the total sale price is distributed to each of us
                            equally (<b>Resale Royalty</b>).
                            <br />
                            <br />
                            We are setting the resale royalty at 2.5% for
                            secondary sales on NFT marketplaces.
                            <br />
                            <br />
                            Note that royalties are dependent on the NFT
                            marketplaces, which also take their own fee of
                            2.5-5% per secondary sale.
                            <br />
                            <br />
                            Resale Royalties are executed and transferred to us
                            at the time of a resale of an Elementals NFT. You
                            agree to be bound by the Elementals NFTs smart
                            contract execution and distribution of the Resale
                            Royalty.
                            <br />
                            <br />
                        </p>
                        <li id="heading">
                            Taxes, gas fees and additional charges
                        </li>
                        <p id="content">
                            <br />
                            <b>Taxes</b>
                            <br /> <br />
                            You are responsible to pay any and all sales, use,
                            value-added and other taxes, duties, levies and
                            assessments now or hereafter claimed or imposed by
                            any governmental authority, associated with your use
                            of our Website (including, without limitation, any
                            taxes that may become payable as a result of
                            ownership, transfer, purchase, sale or minting of
                            any Elementals NFTs or any payments you make using
                            cryptocurrency).
                            <br /> <br />
                            <b>Gas fees</b>
                            <br /> <br />
                            All transactions of Elementals NFTs, including,
                            without limitation; minting and purchasing
                            Elementals NFTs on our Website, are facilitated by
                            the Elementals NFT smart contract deployed on the
                            Avalanche network.
                            <br /> <br />
                            The Avalanche network requires the payment of a
                            transaction fee (<b>Gas Fee</b>) for every
                            transaction that occurs on the Avalanche network,
                            and thus every transaction occurring on our Website.
                            The value of the Gas Fee changes, often
                            unpredictably, and is entirely outside of our
                            control and the Website.
                            <br /> <br />
                            Under no circumstances will a contract, agreement,
                            offer, sale or other transaction using Elementals
                            NFTs be invalidated, revocable, retractable, or
                            otherwise unenforceable on the basis that the Gas
                            Fee for the given transaction was unknown, too high,
                            or otherwise unacceptable to you.
                            <br /> <br />
                            You agree that Gas Fees are non-refundable under any
                            and all circumstances.
                            <br /> <br />
                            <b>Additional charges</b>
                            <br /> <br />
                            Transactions of Elementals NFTs which are paid for
                            in Australian dollars or other fiat currency use a
                            third party online payment gateway Metamask wallet
                            allows users to buy cryptocurrency with visa,
                            mastercard, bank account:{" "}
                            <Link
                                id="link"
                                to={"https://metamask.io/buy-crypto"}
                                target="_blank"
                            >
                                https://metamask.io/buy-crypto
                            </Link>
                            . You may incur payment provider fees and/or credit
                            card fees when purchasing Elementals NFTs.
                            <br />
                            <br />
                        </p>
                        <li id="heading">Purchase process</li>
                        <p id="content">
                            <br />
                            <b>Payment options</b>
                            <br />
                            <br />
                            Elementals NFTs can be purchased on our Website
                            using AVAX tokens, Avalanche’s native token or fiat
                            currency, unless otherwise specified.
                            <br />
                            <br />
                            The wallets such as Metamask will set rates of
                            exchange from fiat to AVAX as available through
                            their exchange.
                            <br />
                            <br />
                        </p>
                        <li id="heading">Prohibited Conduct</li>
                        <p id="content">
                            <br />
                            <b>
                                You are prohibited from manipulating market
                                prices
                            </b>
                            <br />
                            <br />
                            You are expressly forbidden from accepting,
                            soliciting, offering, bidding, engaging with
                            Elementals NFTs, or otherwise transacting on or off
                            of our Website with the intent to artificially
                            devalue, inflate, or otherwise deceptively
                            influence, misrepresent, or cause to be
                            misrepresented the price of Elementals NFTs.
                            <br />
                            <br />
                            <b>
                                You are prohibited from anticompetitive conduct
                            </b>
                            <br />
                            <br />
                            You are generally forbidden from engaging in any
                            deceptive conduct that may prevent competitive or
                            fair bidding, artificially inflate or deflate the
                            price of Elementals NFTs, simulate demand for
                            Elementals NFTs (i.e., "wash trading"), or any other
                            anti-competitive bidding conduct such as but not
                            limited to “puffing,” “chill bidding,” “shill
                            bidding,” “sham bidding,” or “sock puppet bidding.”
                            <br />
                            <br />
                            <b>
                                You are prohibited from using our Website to
                                conceal economic activity
                            </b>
                            <br />
                            <br />
                            You are expressly forbidden from purchasing
                            Elementals NFTs on our Website, or engaging in any
                            other transaction involving Elementals NFTs on our
                            outside of our Website for the purpose of concealing
                            economic activity, laundering money, or financing
                            terrorism.
                            <br />
                            <br />
                            For example, and without limitation, you are
                            forbidden from using Elementals NFTs to conceal or
                            transfer proceeds or assets relating to criminal
                            activity or to pay for an item for any reason other
                            than to obtain the item.
                            <br />
                            <br />
                            You expressly represent and warrant that by
                            performing transactions using Elementals NFTs that
                            your actions are legal under Australian and
                            Tasmanian law and the jurisdiction from which you
                            initiate the transaction.
                            <br />
                            <br />
                            <b>
                                Prohibited conduct may result in intervention by
                                Elementals
                            </b>
                            <br />
                            <br />
                            You hereby agree and acknowledge that any prohibited
                            conduct described in this Item 15 may be a violation
                            of Australian, and or Tasmanian law and/or these
                            Website T&Cs.
                            <br />
                            <br />
                            We hereby reserve the right to completely or
                            partially restrict or revoke your access to the
                            Website for violating these Website T&Cs. We reserve
                            the right to amend, rectify, edit, or otherwise
                            alter our transaction data to mitigate market harm
                            caused by your violation of these terms.
                            <br />
                            <br />
                        </p>
                        <li id="heading">No Returns or Refunds</li>
                        <p id="content">
                            <br />
                            Nothing in these Website T&Cs excludes, restricts or
                            modifies the consumer guarantees provided for by
                            statute including under the Australian Consumer Law.
                            <br />
                            <br />
                            Other than as contemplated by this Item 16, we will
                            not accept returns of, nor refund, purchases of
                            Elementals NFTs.
                            <br />
                            <br />
                            You acknowledge that once the Elementals NFTs smart
                            contract is executed, a transaction using Elementals
                            NFTs cannot be cancelled or reversed.
                            <br />
                            <br />
                        </p>
                        <li id="heading">Seller’s rights and obligations</li>
                        <p id="content">
                            <br />
                            You are permitted to freely resell your Elementals
                            NFT with the following conditions:
                            <br />
                            <br />
                            <ul style={{ listStyle: "disc" }}>
                                <li>
                                    You commit to using another platform that
                                    has interoperability with Elementals NFTs,
                                    that allows for the Resale Royalty to be
                                    distributed to us;
                                </li>
                                <li>
                                    You resell the Elementals NFT with these
                                    Website T&Cs, and you cause the new buyer to
                                    agree to them;
                                </li>
                                <li>
                                    You acknowledge and agree to the warranties
                                    outlined below.
                                </li>
                            </ul>
                            <br />
                            For each sale of Elementals NFTs, the seller
                            warrants that they:
                            <br />
                            <br />
                            <ul style={{ listStyle: "disc" }}>
                                <li>
                                    Are the owner of the Elementals NFTs or a
                                    joint owner of the Elementals NFTs acting
                                    with the permission of the other co-owners,
                                    or if the seller is not the owner or
                                    joint-owner of the Elementals NFTs, have the
                                    permission of the owner to sell the item, or
                                    the right to do so in law;
                                </li>
                                <li>
                                    Have the right to transfer ownership of the
                                    Elementals NFTs to the purchaser without any
                                    restrictions or claims by anyone else,
                                    subject to the payment of the Resale
                                    Royalty;
                                </li>
                                <li>
                                    Have not obtained the Elementals NFTs
                                    illegally or without authorisation.
                                </li>
                            </ul>
                            <br />
                        </p>
                        <li id="heading">Purchaser’s rights and obligations</li>
                        <p id="content">
                            <br />
                            If you purchase or otherwise receive an Elementals
                            NFT, you acknowledge and agree to acquire the item
                            subject to the conditions below.
                            <br />
                            <br />
                            <b>Ownership of Elementals NFTs</b>
                            <br />
                            <br />
                            Owners of Elementals NFTs own the cryptographic
                            token representing the Original Digital Artwork,
                            rather than the Original Digital Artwork file
                            itself.
                            <br />
                            <br />
                            You do not have any legal ownership, right, or title
                            to any copyrights, trade marks, or other
                            intellectual property rights to the original digital
                            artwork, except for the limited rights granted below
                            and any other rights expressly granted to you by us.
                            <br />
                            <br />
                            We reserve all exclusive copyrights to the Original
                            Digital Artwork which is represented by the
                            Elementals NFTs, including the right to reproduce
                            the Original Digital Artwork.
                            <br />
                            <br />
                            <b>Limited Licence Rights</b>
                            <br />
                            <br />
                            Owners of Elementals NFTs are granted the
                            non-exclusive, royalty free, worldwide rights to use
                            the Original Digital Artwork for promoting or
                            sharing their purchase, ownership, or interest in
                            the Elementals NFTs, for example:
                            <br />
                            <br />
                            <ul style={{ listStyle: "disc" }}>
                                <li>
                                    On social media platforms, blogs, digital
                                    galleries, or other internet-based media
                                    platforms;
                                </li>
                                <li>
                                    On third party marketplaces, exchanges,
                                    platforms, or applications in association
                                    with an offer to sell, or trade the
                                    Elementals NFT associated with Original
                                    Digital Artwork;
                                </li>
                                <li>
                                    Within decentralised virtual environments,
                                    virtual worlds, virtual galleries, virtual
                                    museums, or other navigable and perceivable
                                    virtual environments, including simultaneous
                                    display of multiple copies of the Original
                                    Digital Artwork within one or more virtual
                                    environments (Limited Licence Rights).
                                </li>
                            </ul>
                            <br />
                            The above rights to use the Original Digital Artwork
                            for promoting or sharing your purchase, ownership,
                            or interest in the Elementals NFT are subject to the
                            owners of Elementals NFTs crediting us where
                            reasonably possible.
                            <br />
                            <br />
                            <b>Additional rights of the purchaser</b>
                            <br />
                            <br />
                            Owners of Elementals NFTs are also granted the right
                            to transfer their Limited Licence Rights to future
                            purchasers of their Elementals NFTs in accordance
                            with these Website T&Cs. This right to transfer
                            Limited Licence Rights is subject to the owners of
                            Elementals NFTs
                            <br />
                            <br />
                            <b>
                                These rights only belong to a purchaser who is
                                the current owner
                            </b>
                            <br />
                            <br />
                            You agree and acknowledge that the lawful ownership,
                            possession, and title to Elementals NFTs is a
                            necessary and sufficient condition precedent to
                            receiving the Limited Licence Rights to the
                            underlying Original Digital Artwork provided by
                            these Website T&Cs and any additional terms and
                            conditions imposed by us.  
                            <br />
                            <br />
                            Where owners of Elementals NFTs transfer their
                            Elementals NFTs to a subsequent owner, the former
                            owner’s rights and interest in the Limited Licence
                            Rights will transfer to the subsequent owner as
                            provided by these Website T&Cs and any additional
                            terms and conditions imposed by us.
                            <br />
                            <br />
                            Where owners of Elementals NFTs dispossess, burn, or
                            otherwise relinquish their Elementals NFTs their
                            rights and interest in the Limited Licence Rights as
                            provided by these Website T&Cs and any additional
                            terms and conditions imposed by us will immediately
                            terminate.
                            <br />
                            <br />
                            <b>Restrictions on the purchaser</b>
                            <br />
                            <br />
                            Owners of Elementals NFTs agree that they may not,
                            nor permit any third party, to do or attempt to do
                            any of the following without specified approval in
                            writing from us:
                            <br />
                            <br />
                            <ul style={{ listStyle: "disc" }}>
                                <li>
                                    Modify, distort, mutilate, or perform any
                                    other modification to the Original Digital
                                    Artwork or the Elementals NFTs that is in
                                    any manner harmful to our honour or
                                    reputation;
                                </li>
                                <li>
                                    Use the Original Digital Artwork or the
                                    Elementals NFTs to advertise, market, or
                                    sell any third party product or service;
                                </li>
                                <li>
                                    Use the Original Digital Artwork or the
                                    Elementals NFTs in connection with images,
                                    videos, or other forms of media that depict
                                    hatred, intolerance, violence, cruelty, or
                                    anything else that could reasonably be found
                                    to constitute hate speech or otherwise
                                    infringe upon the rights of others;
                                </li>
                                <li>
                                    Incorporate the Original Digital Artwork
                                    into movies, videos, video games, or any
                                    other forms of media for a commercial
                                    purpose, except to the limited extent that
                                    such use is expressly permitted by these
                                    Website T&Cs and solely for their personal,
                                    non-commercial use;
                                </li>
                                <li>
                                    Sell, distribute for commercial gain, or
                                    otherwise commercialise merchandise that
                                    includes, contains, or consists of the
                                    Original Digital Artwork;
                                </li>
                                <li>
                                    Attempt to trade mark or otherwise acquire
                                    additional intellectual property rights in
                                    or to the Original Digital Artwork;
                                </li>
                                <li>
                                    Attempt to mint, tokenise, or create an
                                    additional cryptographic token representing
                                    the same Original Digital Artwork;
                                </li>
                                <li>
                                    Falsify, misrepresent, or conceal the
                                    authorship of the Original Digital Artwork
                                    or the Elementals NFTs; or
                                </li>
                                <li>
                                    Otherwise utilise the Original Digital
                                    Artwork for your or any third party’s
                                    commercial benefit.
                                </li>
                            </ul>
                            <br />
                        </p>
                        <li id="heading">Transactions outside our Website</li>
                        <p id="content">
                            <br />
                            We cannot collect any resale royalties or other
                            charges for transactions occurring outside of our
                            Website that don’t deploy the Elementals NFTs smart
                            contract.
                            <br />
                            <br />
                            Owners of Elementals NFTs are permitted to sell or
                            transfer their Elementals NFTs on third party
                            exchanges.
                            <br />
                            <br />
                            You irrevocably release, acquit, and forever
                            discharge us and our subsidiaries, affiliates,
                            officers, and successors of any liability for
                            royalties, fines, fees or charges not received by
                            you from any transaction occurring outside of our
                            Website involving Elementals NFTs.
                            <br />
                            <br />
                            <br />
                        </p>
                        <p id="header">MORE LEGALS</p>
                        <li id="heading">Our intellectual property rights</li>
                        <p id="content">
                            <br />
                            Unless otherwise indicated in these Website T&Cs, we
                            own or licence from third parties all rights, title
                            and interest (including copyright, designs, patents,
                            trade marks and other intellectual property rights)
                            in this Website, in all of the material (including
                            all logos, text, images, graphics, video, audio,
                            software and artworks) on this Website, in the
                            Elementals NFTs smart contract and in the Original
                            Digital Artwork (<b>Content</b>).
                            <br />
                            <br />
                            Your use of this Website and any Content does not
                            grant or transfer any rights, title or interest to
                            you in relation to our Website or the Content.
                            However, we do grant you a licence to access the
                            Website and view the Content in accordance with
                            these Website T&Cs and, where applicable, as
                            expressly authorised in writing by us.
                            <br />
                            <br />
                            Any reproduction or redistribution of this Website
                            or the Content is prohibited and may result in civil
                            and criminal penalties. In addition, you must not
                            copy the Content to any other server, location or
                            support for publication, reproduction or
                            distribution is expressly prohibited.
                            <br />
                            <br />
                            All other use, copying or reproduction of this
                            Website, the Content or any part thereof is
                            prohibited, except to the extent permitted by law.
                            <br />
                            <br />
                        </p>
                        <li id="heading">No use without permission</li>
                        <p id="content">
                            <br />
                            Unless expressly authorised in writing by us, or to
                            the extent permitted under applicable law, or these
                            Website T&Cs, you may not modify, copy, distribute,
                            transmit, display, perform, reproduce, publish, make
                            adaptations of, licence, transfer, or sell any
                            Content for any commercial or non-commercial
                            purpose.
                            <br />
                            <br />
                        </p>
                        <li id="heading">Unacceptable activity</li>
                        <p id="content">
                            <br />
                            You must not do any act that we would deem to be
                            inappropriate, is unlawful or is prohibited by any
                            laws applicable to our Website, including but not
                            limited to:
                            <br />
                            <br />
                            <ul style={{ listStyle: "disc" }}>
                                <li>
                                    Any act that would constitute a breach of
                                    either the privacy (including uploading
                                    private or personal information without an
                                    individual's consent or any other of the
                                    legal rights of individuals and
                                    impersonating another person via the use of
                                    an email address or otherwise);
                                </li>
                                <li>
                                    Using this Website to defame or libel us,
                                    our employees or other individuals;
                                </li>
                                <li>
                                    Distributing viruses, worms, defects, Trojan
                                    horses, corrupted files, hoaxes or any other
                                    items of a destructive or deceptive
                                    nature;Distributing viruses, worms, defects,
                                    Trojan horses, corrupted files, hoaxes or
                                    any other items of a destructive or
                                    deceptive nature;
                                </li>
                                <li>
                                    Uploading, transmitting or otherwise making
                                    available through our Website any content
                                    that infringes the intellectual property
                                    rights of any third party;
                                </li>
                                <li>
                                    Posting or transmitting to this Website any
                                    non-authorised material including, but not
                                    limited to, material that is, in our
                                    opinion, likely to cause annoyance, or which
                                    is defamatory, racist, obscene, threatening,
                                    pornographic or otherwise or which is
                                    detrimental to or in violation of our
                                    systems or a third party's systems or
                                    network security;
                                </li>
                                <li>
                                    Engage in, promote or encourage illegal
                                    activity (including, without limitation,
                                    terrorism, tax evasion or money laundering);
                                    and
                                </li>
                                <li>
                                    Engaging in any attack, hack,
                                    denial-of-service attack, interference, or
                                    exploit of any Elementals NFT smart
                                    contract. Operations performed by you that
                                    are technically permitted by an Elementals
                                    NFT smart contract may nevertheless be a
                                    violation of our Website T&Cs and the law.
                                </li>
                            </ul>
                            <br />
                            If we allow you to upload or otherwise make
                            available any material or information on our
                            Website, we have the right to take down this
                            material or information at our sole discretion and
                            without notice.
                            <br />
                            <br />
                        </p>
                        <li id="heading">
                            Accuracy, completeness and timeliness of information
                        </li>
                        <p id="content">
                            <br />
                            The information on our Website is not comprehensive
                            and is intended to provide a summary of the subject
                            matter covered.
                            <br />
                            <br />
                            While we use all reasonable attempts to ensure the
                            accuracy and completeness of the information on our
                            Website, to the extent permitted by law, including
                            the Australian Consumer Law in Schedule 2 of the{" "}
                            <i>Competition and Consumer Act 2010</i> (
                            <b>Australian Consumer Law</b>), we make no warranty
                            regarding the information on this Website.
                            <br />
                            <br />
                            You should not rely upon the content of any such
                            information as a basis for making any decisions
                            about the purchase of an artwork or otherwise. You
                            should monitor any changes to the information
                            contained on this Website.
                            <br />
                            <br />
                            We are not liable to you or anyone else if
                            interference with, or damage to your internet or
                            computer systems occurs in connection with the use
                            of this Website or a linked Website. You must take
                            your own precautions to ensure that whatever you
                            select for your use from our Website is free of
                            viruses or anything else (such as worms or Trojan
                            horses) that may interfere with or damage the
                            operations of your computer systems.
                            <br />
                            <br />
                            We may, from time to time and without notice, change
                            or add to the Website (including these Website T&Cs)
                            or the information or services presented or
                            described in it. However, we do not undertake to
                            keep the Website updated. We are not liable to you
                            or anyone else if errors occur in the information on
                            the Website or if that information is not
                            up-to-date.
                            <br />
                            <br />
                        </p>
                        <li id="heading">
                            Third party information and linked sites
                        </li>
                        <p id="content">
                            <br />
                            Our Website may contain information from third
                            parties, including hyperlinks to Websites operated
                            by third parties. Such data or information is not
                            under our direct control and has not been verified
                            by us. Neither we, nor the relevant third party
                            provider, make any representation or warranty as to
                            the accuracy, timeliness, reliability,
                            appropriateness or completeness of any third party
                            data or information.
                            <br />
                            <br />
                            We accept no responsibility for any connectivity
                            issues, errors or disruptions that may affect the
                            performance or functionality of our Website.
                            <br />
                            <br />
                        </p>
                        <li id="heading">Warranties and disclaimers</li>
                        <p id="content">
                            <br />
                            To the maximum extent permitted by law, including
                            the Australian Consumer Law and the{" "}
                            <i>Copyright Act 1968</i> (Cth), we make no
                            warranties or representations about our Website or
                            the Content, including warranties or representations
                            that:
                            <br />
                            <br />
                            <ul style={{ listStyle: "disc" }}>
                                <li>
                                    Our Website and the Content will be
                                    complete, accurate or up-to-date;
                                </li>
                                <li>
                                    Access to our Website will be uninterrupted
                                    or error-free or free from viruses or
                                    malware;
                                </li>
                                <li>Our Website will be secure; or that</li>
                                <li>
                                    The Content does not infringe on the
                                    copyright or moral rights of any third
                                    party.
                                </li>
                            </ul>
                            <br />
                            We reserve the right to restrict, suspend or
                            terminate without notice your access to this Website
                            and/or any Content at any time without notice and we
                            will not be responsible for any indirect or direct
                            loss, cost, damage or liability that may arise as a
                            result.
                            <br />
                            <br />
                        </p>
                        <li id="heading">Liability</li>
                        <p id="content">
                            <br />
                            To the maximum extent permitted by law, including
                            the Australian Consumer Law, in no event shall we be
                            liable for any direct and indirect loss, damage or
                            expense - irrespective of the manner in which it
                            occurs - which may be suffered due to:
                            <br />
                            <br />
                            <ul style={{ listStyle: "disc" }}>
                                <li>
                                    Your use of our Website or the Content on
                                    our Website;
                                </li>
                                <li>
                                    The inaccessibility of our Website or our
                                    related services;
                                </li>
                                <li>
                                    Corrupted or broken URL hashes in Elementals
                                    NFTs smart contracts;
                                </li>
                                <li>
                                    Technical errors on the Avalanche or
                                    Ethereum platforms, including forks,
                                    technical node issues or any other issues
                                    which cause direct or indirect loss as a
                                    result;
                                </li>
                                <li>
                                    Upgrades to the Avalanche or Ethereum
                                    platforms, a hard fork in the Avalanche or
                                    Ethereum platforms, or a change in how
                                    transactions are confirmed on the Avalanche
                                    platform;
                                </li>
                                <li>
                                    User error, such as forgotten passwords or
                                    incorrectly construed Elementals NFT smart
                                    contracts or other transactions;
                                </li>
                                <li>Server failure or data loss;</li>
                                <li>Corrupted digital wallet files;</li>
                                <li>
                                    Unauthorised access or activities by third
                                    parties, including but not limited to the
                                    use of viruses, phishing, brute-forcing or
                                    other means of attack against our Website,
                                    the Ethereum network, the Avalanche network,
                                    Compatible Digital Wallets or other
                                    Ethereum-compatible browser or digital
                                    wallets; or
                                </li>
                                <li>
                                    The fact that certain information or
                                    materials contained on our Website or that
                                    we otherwise share with you are incorrect,
                                    incomplete or not up-to-date.
                                </li>
                            </ul>
                            <br />
                            Our liability for negligence, breach of contract or
                            contravention of any laws as a result of our failure
                            to provide our Website or any part of it, or for any
                            problems with our Website, which cannot be lawfully
                            excluded, is limited to resupplying this Website or
                            any part of it to you or to paying for the resupply
                            of this Website or any part of it to you (at our
                            option).
                            <br />
                            <br />
                        </p>
                        <li id="heading">Indemnity</li>
                        <p id="content">
                            <br />
                            You agree to indemnify us jointly and severally, in
                            respect of all loss, damage, cost, claim or demand
                            including reasonable legal fees, fines, penalties or
                            expenses incurred or suffered by us in connection
                            with your use of our Website in breach of these
                            Website T&Cs.
                            <br />
                            <br />
                        </p>
                        <li id="heading">
                            Modification and assignment of Website T&Cs
                        </li>
                        <p id="content">
                            <br />
                            We reserve the right, in our sole and absolute
                            discretion, and without prior notice to you, to:
                            <ul style={{ listStyle: "disc" }}>
                                <li>
                                    To amend, modify, alter or supplement these
                                    Website T&Cs
                                </li>
                                <li>
                                    Assign these Website T&Cs to any of our
                                    affiliates, or to our successors in interest
                                    of any business associated with the services
                                    provided by us.
                                </li>
                            </ul>
                            <br />
                            These Website T&Cs shall be binding upon the
                            permitted assigns or transferees of each party.
                            <br />
                            <br />
                        </p>
                        <li id="heading">Jurisdiction and governing law</li>
                        <p id="content">
                            <br />
                            Your use of our Website and these General Terms of
                            Use are governed by the law of Tasmania, Australia
                            and you submit to the non-exclusive jurisdiction of
                            the courts exercising jurisdiction in Tasmania,
                            Australia.
                            <br />
                        </p>
                        <p id="header">Get in touch</p>
                        <li id="heading">Contact Us</li>
                        <p id="content">
                            <br />
                            If you have any questions or would like further
                            information about our Website T&Cs you can get in
                            touch with us at info@elementalsnfts.com.
                            <br />
                            <br />
                        </p>
                        <div className="final">
                            <p id="content">
                                <b>Effective From:</b> 18 May 2023
                            </p>
                            <p id="content">
                                <b>Last Updated:</b> 18 May 2023
                            </p>
                        </div>
                    </ol>
                </div>
                <Link id="link-tag" to={"/"}>
                    <div className="btn">
                        <p id="link_2">Back to main page</p>
                    </div>
                </Link>
            </div>
        </div>
    );
};

export default Terms;
