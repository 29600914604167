// Libraries import
import React from "react";
import { FC } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { IoIosArrowDropdown, IoIosArrowDropdownCircle } from "react-icons/io";

// Styling import
import "./FAQs.scss";

// Components import
import { faqsProps } from "../../utils/props";

const FAQs: FC<faqsProps> = ({ faqs, width }) => {
    const [question_1, setQuestion_1] = useState(true);
    const [question_2, setQuestion_2] = useState(true);
    const [question_3, setQuestion_3] = useState(true);
    const [question_4, setQuestion_4] = useState(true);
    const [question_5, setQuestion_5] = useState(true);
    const [question_6, setQuestion_6] = useState(true);
    const [question_7, setQuestion_7] = useState(true);
    const [question_8, setQuestion_8] = useState(true);
    const [question_9, setQuestion_9] = useState(true);
    const [question_10, setQuestion_10] = useState(true);
    const [question_11, setQuestion_11] = useState(true);
    const [question_12, setQuestion_12] = useState(true);
    const [question_13, setQuestion_13] = useState(true);
    const [question_14, setQuestion_14] = useState(true);

    return (
        <div className="FAQs" ref={faqs}>
            <p id="header">Frequently asked questions</p>
            <div className="container">
                <div className="questions">
                    <div className="question">
                        <div
                            className="header"
                            onClick={() => {
                                setQuestion_1(!question_1);
                            }}
                        >
                            <p id="question-text">
                                What are NFTs? How do they work?
                            </p>
                            <div className="btn">
                                {question_1 ? (
                                    <IoIosArrowDropdown id="down" size="40" />
                                ) : (
                                    <IoIosArrowDropdownCircle
                                        id="up"
                                        size="40"
                                    />
                                )}
                            </div>
                        </div>
                        {!question_1 && (
                            <div id="answer">
                                <div className="divider"></div>
                                <p id="text">
                                    An NFT is a type of digital asset.
                                    <br />
                                    Think of an Elementals NFT as a digital
                                    artwork, but with a value and originality—
                                    this is proved through the blockchain.
                                    <br /> <br />
                                    When you buy an NFT, you alone have the
                                    right to transfer it or sell it. The NFT
                                    token proves that yours ALONE is the
                                    original. It is like owning a signed
                                    painting.
                                    <br /> <br />
                                    The data about who owns the NFT and the
                                    history of its ownership is stored on the
                                    blockchain. This is the technology that
                                    underlies cryptocurrencies like Bitcoin and
                                    Ethereum. The blockchain creates a traceable
                                    record each time an NFT changes hands.
                                    <br /> <br />
                                    NFTs aren't just limited to art. They can
                                    also be used for music, sound, video and
                                    even legal or real estate documents. NFTs
                                    are becoming more commonplace. Think about
                                    the internet in the late 1990s or the iPhone
                                    in the 2010s.
                                    <br /> <br />
                                    An example of a use case— a singer releases
                                    a new album— each song could be an NFT,
                                    which means we can see/prove who the owner
                                    is. If you (or a group of people) decide to
                                    buy one of her NFT songs, you can then earn
                                    royalties on it. They would automatically be
                                    paid out to your wallet. The middle man
                                    (lawyers, agents, etc) can be cut out of
                                    this process. More info here:{" "}
                                    <Link
                                        id="link"
                                        target="_blank"
                                        to={
                                            "https://www.forbes.com/advisor/au/investing/cryptocurrency/nft-non-fungible-token/"
                                        }
                                    >
                                        {width > 1070
                                            ? "https://www.forbes.com/advisor/au/investing/cryptocurrency/nft-non-fungible-token/"
                                            : "https://www.forbes.comadvisor/au/..."}
                                    </Link>
                                </p>
                            </div>
                        )}
                    </div>
                    <div className="question">
                        <div
                            className="header"
                            onClick={() => {
                                setQuestion_2(!question_2);
                            }}
                        >
                            <p id="question-text">
                                Can I buy an Elemental NFT with credit card?
                            </p>
                            <div className="btn">
                                {question_2 ? (
                                    <IoIosArrowDropdown id="down" size="40" />
                                ) : (
                                    <IoIosArrowDropdownCircle
                                        id="up"
                                        size="40"
                                    />
                                )}
                            </div>
                        </div>
                        {!question_2 && (
                            <div id="answer">
                                <div className="divider"></div>
                                <p id="text">
                                Yes, you can automatically buy with a credit card through Metamask but note that some banks may automatically decline cryptocurrency purchases. This is due to recent regulatory changes and evolving financial industry practices. If this happens, a call to your bank will usually allow your purchase to go through.
                                <br /><br />
                                To avoid potential inconveniences when purchasing cryptocurrency with a credit or debit card for the first time we recommend the following:
                                <ul>
                                    <li>
                                        <p id="text">
                                            Contact your bank: Before making a cryptocurrency purchase, reach out to your bank or check their website to understand their policy on such transactions.
                                        </p>
                                    </li>
                                    <li>
                                        <p id="text">
                                            Explore alternatives: If your bank does not support cryptocurrency purchases, consider using peer-to-peer transfers or an alternative bank.
                                        </p>
                                    </li>
                                    <li>
                                        <p id="text">
                                            Stay informed: Stay updated on global and local regulations concerning crypto currency transactions.
                                        </p>
                                    </li>
                                </ul>
                                Please note that we cannot guarantee that all banks will accept cryptocurrency purchases. Each bank has its own policies and risk assessment protocols that may result in automatic transaction declines.
                                </p>
                            </div>
                        )}
                    </div>
                    <div className="question">
                        <div
                            className="header"
                            onClick={() => {
                                setQuestion_3(!question_3);
                            }}
                        >
                            <p id="question-text">
                                How do I display my NFT collection?
                            </p>
                            <div className="btn">
                                {question_3 ? (
                                    <IoIosArrowDropdown id="down" size="40" />
                                ) : (
                                    <IoIosArrowDropdownCircle
                                        id="up"
                                        size="40"
                                    />
                                )}
                            </div>
                        </div>
                        {!question_3 && (
                            <div id="answer">
                                <div className="divider"></div>
                                <p id="text">
                                    You can display your NFT collection in the
                                    metaverse in a virtual museum or exhibition
                                    space such as Decentraland or Cryptovoxels
                                    where it can be seen by the public. More
                                    reading:{" "}
                                    <Link
                                        id="link"
                                        target="_blank"
                                        to={
                                            "https://everyrealm.com/blog/education/metaverse-art"
                                        }
                                    >
                                        {width > 1070
                                            ? "https://everyrealm.com/blog/education/metaverse-art"
                                            : "https://everyrealm.com/blog/..."}
                                    </Link>
                                    <br /> <br />
                                    Or you can view your NFTs online and share
                                    the page through OpenSea, Rarible, and also
                                    through twitter as your verified profile
                                    photo.
                                    <br /> <br />
                                    Additionally, many collectors use monitors
                                    or digital NFT frames in their homes to
                                    display their NFT art.
                                </p>
                            </div>
                        )}
                    </div>
                    <div className="question">
                        <div
                            className="header"
                            onClick={() => {
                                setQuestion_4(!question_4);
                            }}
                        >
                            <p id="question-text">
                                What blockchain are Elemental NFTs on?
                            </p>
                            <div className="btn">
                                {question_4 ? (
                                    <IoIosArrowDropdown id="down" size="40" />
                                ) : (
                                    <IoIosArrowDropdownCircle
                                        id="up"
                                        size="40"
                                    />
                                )}
                            </div>
                        </div>
                        {!question_4 && (
                            <div id="answer">
                                <div className="divider"></div>
                                <p id="text">
                                    Elementals are on the Avalanche blockchain.
                                    <br />
                                    Why Avalanche? Similar to Ethereum,
                                    Avalanche is secure layer 1 blockchain but
                                    also fast and cheap to use. Avalanche (and
                                    its native token $AVAX) is also more
                                    decentralised and has not had downtime like
                                    other newer blockchains.
                                    <br /> <br />
                                    Although there are currently less NFTs on
                                    Avalanche than on Ethereum or Solana, we are
                                    looking to create a long-term project and
                                    foresee huge growth on the Avalanche network
                                    in the future.
                                </p>
                            </div>
                        )}
                    </div>
                    <div className="question">
                        <div
                            className="header"
                            onClick={() => {
                                setQuestion_5(!question_5);
                            }}
                        >
                            <p id="question-text">
                                How much is an Elemental NFT?
                            </p>
                            <div className="btn">
                                {question_5 ? (
                                    <IoIosArrowDropdown id="down" size="40" />
                                ) : (
                                    <IoIosArrowDropdownCircle
                                        id="up"
                                        size="40"
                                    />
                                )}
                            </div>
                        </div>
                        {!question_5 && (
                            <div id="answer">
                                <div className="divider"></div>
                                <p id="text">
                                    Elementals are priced in $AVAX, the native token of Avalanche which is around
                                    $13 USD (as of July 5 2023), but remember that $AVAX can fluctuate.
                                    <br /> <br />
                                    1 NFT is 18.5 AVAX (without whitelist discount), this is around $240 USD / 220 EURO / 188 GBP / 360 AUD (as of 5 July 2023 with an AVAX price of $13).
                                    <br /> <br />
                                    Or 15.5 AVAX (with Whitelist discount), is around $201 USD / 184 EURO / 158 GBP / 300 AUD (as of 5 July 2023 with an AVAX price of $13).
                                    <br /> <br />
                                </p>
                            </div>
                        )}
                    </div>
                    <div className="question">
                        <div
                            className="header"
                            onClick={() => {
                                setQuestion_6(!question_6);
                            }}
                        >
                            <p id="question-text">
                                What are the advantages of owning an Elemental
                                NFT?
                            </p>
                            <div className="btn">
                                {question_6 ? (
                                    <IoIosArrowDropdown id="down" size="40" />
                                ) : (
                                    <IoIosArrowDropdownCircle
                                        id="up"
                                        size="40"
                                    />
                                )}
                            </div>
                        </div>
                        {!question_6 && (
                            <div id="answer">
                                <div className="divider"></div>
                                <ul>
                                    <li>
                                        <p id="text">
                                            A unique digital art collectible
                                        </p>
                                    </li>
                                    <li>
                                        <p id="text">
                                            Exclusive access to group zoom
                                            events with Jessica Adams
                                        </p>
                                    </li>
                                    <li>
                                        <p id="text">
                                            A future free NFT drop in 2024
                                        </p>
                                    </li>
                                    <li>
                                        <p id="text">
                                            Be part of a project backed by 3
                                            founders who have considerable
                                            experience in the fields of
                                            astrology and contemporary art
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        )}
                    </div>
                    <div className="question">
                        <div
                            className="header"
                            onClick={() => {
                                setQuestion_7(!question_7);
                            }}
                        >
                            <p id="question-text">How do I get the discount?</p>
                            <div className="btn">
                                {question_7 ? (
                                    <IoIosArrowDropdown id="down" size="40" />
                                ) : (
                                    <IoIosArrowDropdownCircle
                                        id="up"
                                        size="40"
                                    />
                                )}
                            </div>
                        </div>
                        {!question_7 && (
                            <div id="answer">
                                <div className="divider"></div>
                                <p id="text">
                                    The 15% discount offer is over now, and only
                                    applied for those who signed up before 19th
                                    May.
                                </p>
                            </div>
                        )}
                    </div>
                    <div className="question">
                        <div
                            className="header"
                            onClick={() => {
                                setQuestion_8(!question_8);
                            }}
                        >
                            <p id="question-text">
                                Do I have to spend a lot of money on gas
                                (transaction fees) to buy an Elemental NFT?
                            </p>
                            <div className="btn">
                                {question_8 ? (
                                    <IoIosArrowDropdown id="down" size="40" />
                                ) : (
                                    <IoIosArrowDropdownCircle
                                        id="up"
                                        size="40"
                                    />
                                )}
                            </div>
                        </div>
                        {!question_8 && (
                            <div id="answer">
                                <div className="divider"></div>
                                <p id="text">
                                    No, you will only spend a few cents for any
                                    transaction fees (also called 'gas') as we
                                    use the Avalanche network, which has cheap
                                    fees and very fast transactions in
                                    comparison to NFTs on networks such as
                                    Ethereum where gas can be pricey.
                                </p>
                            </div>
                        )}
                    </div>
                    <div className="question">
                        <div
                            className="header"
                            onClick={() => {
                                setQuestion_9(!question_9);
                            }}
                        >
                            <p id="question-text">
                                Do I own the copyright of an Elemental NFT?
                            </p>
                            <div className="btn">
                                {question_9 ? (
                                    <IoIosArrowDropdown id="down" size="40" />
                                ) : (
                                    <IoIosArrowDropdownCircle
                                        id="up"
                                        size="40"
                                    />
                                )}
                            </div>
                        </div>
                        {!question_9 && (
                            <div id="answer">
                                <div className="divider"></div>
                                <p id="text">
                                    Like the majority of art pieces, Elementals
                                    artwork copyright stays with the 3 founders—
                                    Jessica Adams, Anita Bacic and Tintin
                                    Cooper, and is not transferred to NFT
                                    buyers.
                                </p>
                            </div>
                        )}
                    </div>
                    <div className="question">
                        <div
                            className="header"
                            onClick={() => {
                                setQuestion_10(!question_10);
                            }}
                        >
                            <p id="question-text">
                                What are the differences between the Elementals
                                NFTs? Which ones are more rare?
                            </p>
                            <div className="btn">
                                {question_10 ? (
                                    <IoIosArrowDropdown id="down" size="40" />
                                ) : (
                                    <IoIosArrowDropdownCircle
                                        id="up"
                                        size="40"
                                    />
                                )}
                            </div>
                        </div>
                        {!question_10 && (
                            <div id="answer">
                                <div className="divider"></div>
                                <p id="text">
                                    Elementals are already quite rare in
                                    comparison to many NFT projects which often
                                    run into thousands of editions.
                                    <br /> <br />
                                    You can check the following for the rarity
                                    of your NFT:
                                    <br /> <br />
                                    Fire, Earth, Air, Water = 64/256 NFTs = 25%
                                    chance each <br />
                                    12 Astrological Signs = 10/256 NFTs = 3%
                                    chance each <br />
                                    12 Quirks = 10/256 NFTs = 3% chance each
                                    <br /> <br />
                                    Partial Eclipse NFTs = 4/256 = 7 = 1% chance
                                    each
                                    <br />
                                    Total Eclipse NFTs (totally b/w) = 2/256
                                    NFTs = 0.7% chance
                                    <br /> <br />
                                    Aether (1 NFT, combination of all elements)
                                    = 0% totally unique!
                                    <br /> <br />* We will release a detailed
                                    table of NFT rarities (with backgrounds,
                                    patterns, etc) after the collection has sold
                                    out, so keep an eye out to find out if you
                                    have an extra rare gem!
                                </p>
                            </div>
                        )}
                    </div>
                    <div className="question">
                        <div
                            className="header"
                            onClick={() => {
                                setQuestion_11(!question_11);
                            }}
                        >
                            <p id="question-text">
                                What is the future roadmap for Elementals NFTs?
                            </p>
                            <div className="btn">
                                {question_11 ? (
                                    <IoIosArrowDropdown id="down" size="40" />
                                ) : (
                                    <IoIosArrowDropdownCircle
                                        id="up"
                                        size="40"
                                    />
                                )}
                            </div>
                        </div>
                        {!question_11 && (
                            <div id="answer">
                                <div className="divider"></div>
                                <p id="text">
                                    This first edition drop in June 2023 is just
                                    the beginning! <br />
                                    We are planning a bigger, ambitious second
                                    drop in 2024.
                                    <br /> <br />
                                    Those who subscribed to Early Access of our
                                    1st edition will be rewarded in the future
                                    with free NFT airdrops, so aim to have at
                                    least 1 Elemental NFT in your wallet in
                                    2024.
                                </p>
                            </div>
                        )}
                    </div>
                    <div className="question">
                        <div
                            className="header"
                            onClick={() => {
                                setQuestion_12(!question_12);
                            }}
                        >
                            <p id="question-text">
                                Don't NFTs use up the electricity of a small
                                country?
                            </p>
                            <div className="btn">
                                {question_12 ? (
                                    <IoIosArrowDropdown id="down" size="40" />
                                ) : (
                                    <IoIosArrowDropdownCircle
                                        id="up"
                                        size="40"
                                    />
                                )}
                            </div>
                        </div>
                        {!question_12 && (
                            <div id="answer">
                                <div className="divider"></div>
                                <p id="text">
                                    No, NFTs no longer use up crazy amounts of
                                    electricity.
                                    <br /> <br />
                                    The big electricity guzzler in 2020 was the
                                    Ethereum blockchain, but it has now
                                    transitioned to a proof-of-stake system
                                    which uses much less energy. It currently
                                    uses around 0.01 TWh/year compared with
                                    Youtube -244 TWh, Netflix -94 TWh and Paypal
                                    -0.26 Twh.
                                    <br />
                                    More about energy consumption:{" "}
                                    <Link
                                        id="link"
                                        to={
                                            "https://ethereum.org/en/energy-consumption"
                                        }
                                        target="_blank"
                                    >
                                        https://ethereum.org/en/energy-consumption
                                    </Link>
                                    <br /> <br />
                                    The UN now advocates blockchain as a
                                    potential sustainable solution against
                                    climate change. More reading here:{" "}
                                    <Link
                                        id="link"
                                        to={
                                            "https://www.unep.org/news-and-stories/story/battle-against-climate-crisis-dont-overlook-blockchain"
                                        }
                                        target="_blank"
                                    >
                                        https://www.unep.org/news-and-stories/story/battle-against-climate-crisis-dont-overlook-blockchain
                                    </Link>
                                </p>
                            </div>
                        )}
                    </div>
                    <div className="question">
                        <div
                            className="header"
                            onClick={() => {
                                setQuestion_13(!question_13);
                            }}
                        >
                            <p id="question-text">Do I choose my NFTs?</p>
                            <div className="btn">
                                {question_13 ? (
                                    <IoIosArrowDropdown id="down" size="40" />
                                ) : (
                                    <IoIosArrowDropdownCircle
                                        id="up"
                                        size="40"
                                    />
                                )}
                            </div>
                        </div>
                        {!question_13 && (
                            <div id="answer">
                                <div className="divider"></div>
                                <p id="text">
                                    No, an NFT is randomly given to you - you
                                    receive it immediately in your wallet after
                                    purchase.
                                </p>
                            </div>
                        )}
                    </div>
                    <div className="question">
                        <div
                            className="header"
                            onClick={() => {
                                setQuestion_14(!question_14);
                            }}
                        >
                            <p id="question-text">
                                Are Elementals generative digital art?
                            </p>
                            <div className="btn">
                                {question_14 ? (
                                    <IoIosArrowDropdown id="down" size="40" />
                                ) : (
                                    <IoIosArrowDropdownCircle
                                        id="up"
                                        size="40"
                                    />
                                )}
                            </div>
                        </div>
                        {!question_14 && (
                            <div id="answer">
                                <div className="divider"></div>
                                <p id="text">
                                    Elementals may look like they are
                                    computer-generated due to the repeating
                                    motifs, but they are in fact all hand-drawn
                                    by artists Tintin Cooper and Anita Bacic.
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FAQs;
