// Libraries import
import React, { FC } from "react";
import { useState, useEffect } from "react";
import { isMobile } from "react-device-detect";

// Styling import
import "./Mint.scss";
import logo from "../../resources/png/icons/elementals-logo.png";
import { Provider } from "ethers";
import {
    handleMint,
    handleNetworkChange,
    handlePageLoad,
    handleWalletConnect,
} from "../../utils/handler";
import nft_1 from "../../resources/png/mint-icons/nft_1.png";
import nft_2 from "../../resources/png/mint-icons/nft_2.png";
import nft_3 from "../../resources/png/mint-icons/nft_3.png";
import nft_4 from "../../resources/png/mint-icons/nft_4.png";
import nft_5 from "../../resources/png/mint-icons/nft_5.png";
import nft_6 from "../../resources/png/mint-icons/nft_6.png";
import nft_7 from "../../resources/png/mint-icons/nft_7.png";
import nft_8 from "../../resources/png/mint-icons/nft_8.png";

// Components import
import { mintProps } from "../../utils/props";
import { normalWallet } from "../../resources/values";
import { Link } from "react-router-dom";

const Mint: FC<mintProps> = ({ width, mint }) => {
    const [text, setText] = useState<string>("connect");
    const [, setProvider] = useState<Provider>();
    const [walletStyle, setWalletStyle] =
        useState<React.CSSProperties>(normalWallet);
    const [isMinted, setIsMinted] = useState(false);
    const [NFTImage, setNFTImage] = useState("");

    // Handle network change
    useEffect(() => {
        handleNetworkChange(window, setText, setProvider, setWalletStyle);
    }, []);

    // Handle page load
    useEffect(() => {
        handlePageLoad(window, setText, setProvider, setWalletStyle);
    }, []);

    // Handle wallet connection
    const connectWallet = async () => {
        handleWalletConnect(window, setText, setProvider, setWalletStyle);
    };

    // Handle NFT mint
    const mintNFT = async () => {
        await handleMint(
            window,
            setText,
            setWalletStyle,
            setIsMinted,
            setNFTImage
        );
    };

    return (
        <div className="Mint" ref={mint}>
            {!isMinted ? (
                <div className="container">
                    <img src={logo} alt="Elementas NFTs logo" />
                    <div className="main-text">
                        <p id="title">256 Unique nfts!</p>
                        <p id="subtitle">
                            A modern take on the classical elements & astrology
                        </p>
                    </div>
                    <div className="images">
                        <img src={nft_1} alt="1st icon image" />
                        <img src={nft_2} alt="1st icon image" />
                        <img src={nft_3} alt="1st icon image" />
                        <img src={nft_4} alt="1st icon image" />
                        {!isMobile && width > 1130 && (
                            <img src={nft_5} alt="1st icon image" />
                        )}
                        {!isMobile && width > 1300 && (
                            <img src={nft_6} alt="1st icon image" />
                        )}
                        {!isMobile && width > 1500 && (
                            <img src={nft_7} alt="1st icon image" />
                        )}
                        {!isMobile && width > 1500 && (
                            <img src={nft_8} alt="1st icon image" />
                        )}
                    </div>
                    <div className="wallet-container">
                        <div
                            className="wallet"
                            style={walletStyle}
                            onClick={() => {
                                connectWallet();
                            }}
                        >
                            <p>{text}</p>
                        </div>
                    </div>
                    <div
                        className="btn-container"
                        onClick={() => {
                            mintNFT();
                        }}
                    >
                        <div className="btn">
                            <p>Mint</p>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="container-minted">
                    <img id="logo" src={logo} alt="Elementas NFTs logo" />
                    <div className="main-text">
                        <p id="title">You are now a master of the elements!</p>
                        <p id="subtitle">Check your wallet to see your nfts.</p>
                    </div>
                    <img id="nft" src={NFTImage} alt="Minted NFT" />
                    <div className="question">
                        <p id="subtitle">Questions?</p>
                        <p id="subtitle">
                            Ask on{" "}
                            <Link
                                id="subtitle"
                                to={"http://twitter.com/astroelementals"}
                                target="_blank"
                            >
                                twitter
                            </Link>
                        </p>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Mint;
