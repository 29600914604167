import { toast } from "react-toastify";
import { isMobile } from "react-device-detect";

const style: React.CSSProperties = isMobile
    ? {
          position: "fixed",
          top: "20",
          right: "0",
          fontSize: "2rem",
          width: "40rem",
          fontFamily: "'Futura', 'Josefin Sans'",
          border: "0.1rem solid #282828",
          borderRadius: "1rem",
      }
    : {
          fontFamily: "'Futura', 'Josefin Sans'",
          border: "0.1rem solid #282828",
          borderRadius: "1rem",
      };

const error = (message: string, icon: any) => {
    toast(message, {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "dark",
        icon: icon,
        style: style,
        progressStyle: {
            backgroundColor: "red",
        },
    });
};

const success = (message: string, icon: any) => {
    toast(message, {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "dark",
        icon: icon,
        style: style,
        progressStyle: {
            backgroundColor: "green",
        },
    });
};

export { error, success };
