// Libraries import
import React from "react";
import { FC } from "react";
import { useState } from "react";

// Styling import
import "./Elements.scss";
import air_icon from "../../resources/png/icons/air-icon.png";
import earth_icon from "../../resources/png/icons/earth-icon.png";
import fire_icon from "../../resources/png/icons/fire-icon.png";
import water_icon from "../../resources/png/icons/water-icon.png";

// Components import
import { elementsProps } from "../../utils/props";

const Elements: FC<elementsProps> = ({ elements }) => {
    const [btnText, setBtnText] = useState("show more");
    const [visible, setVisible] = useState(false);

    const btnHandle = () => {
        setVisible(!visible);
        if (visible) {
            setBtnText("Show more");
        } else {
            setBtnText("Show less");
        }
    };

    return (
        <div className="Elements" ref={elements}>
            <p id="header">the 4 elements in Astrology</p>
            <div className="container">
                <div className="elements">
                    <div className="first">
                        <div className="element" id="first-child">
                            <img src={air_icon} alt="Air element" />
                            <p id="name">air</p>
                            <p id="description">
                                Associated with movement and breathing new life
                                into situations.
                            </p>
                        </div>
                        <div className="element">
                            <img src={earth_icon} alt="Earth element" />
                            <p id="name">earth</p>
                            <p id="description">
                                Associated with productivity, a stabilising
                                influence and reliability.
                            </p>
                        </div>
                    </div>
                    <div className="second">
                        <div className="element" id="first-child">
                            <img src={fire_icon} alt="Fire element" />
                            <p id="name">fire</p>
                            <p id="description">
                                Associated with the qualities of energy,
                                assertiveness and passion.
                            </p>
                        </div>
                        <div className="element">
                            <img src={water_icon} alt="Water element" />
                            <p id="name">water</p>
                            <p id="description">
                                Associated with the qualities of emotion,
                                immersion and intuition.
                            </p>
                        </div>
                    </div>
                </div>
                <p id="elements-description">
                    The Elements are also known as Triplicities, because they
                    contain three zodiac signs each, the Elements show the
                    nature of the signs, referring to the natural world. This
                    aspect of astrology comes from Ancient Greece.
                    <br /> <br />
                    The Sun Signs are compatible with each other when they are
                    in complementary elements. Fire and Fire. Fire and Air.
                    Earth and Earth. Earth and Water. Air and Air. Air and Fire.
                    Water and Water. Water and Earth.
                </p>
                {visible && (
                    <div className="elements-descriptions">
                        <div className="element-description">
                            <p id="element-header">
                                FIRE / ARIES, LEO, SAGITTARIUS
                            </p>
                            <p id="element-text">
                                Fire releases heat and light. Fire signs are
                                warm and welcoming. Aries, Leo and Sagittarius
                                also shed light on situations and illuminate
                                them. Fire sign people can become intense. Too
                                hot to handle. They convert (cook) raw materials
                                into results which are changed forever. Fire
                                signs transform. They are creative. They can
                                also destroy. In the Smith-Waite Tarot, fire is
                                represented by the Wands suit. Fire signs are
                                brought to life by Air signs – Gemini, Libra,
                                Aquarius and expand through them. Water signs
                                can work against them – Cancer, Scorpio, Pisces.
                            </p>
                        </div>
                        <div className="element-description">
                            <p id="element-header">
                                EARTH / TAURUS, VIRGO, CAPRICORN
                            </p>
                            <p id="element-text">
                                Earth is heavy and grounding. Earth signs are
                                productive. They stabilise situations which are
                                going concerns, and growing concerns, as rich
                                soil grows fruit and vegetables, herbs, flowers
                                and trees. Taurus, Virgo and Capricorn are
                                down-to-earth. In the Smith-Waite Tarot, earth
                                is represented by the Pentacles suit, which
                                shows gold coins. The earth signs contain, hold
                                and support the water signs – Cancer, Scorpio,
                                Pisces. They can work against the fire signs –
                                Aries, Leo, Sagittarius. The earth signs are
                                deep and have many layers.
                            </p>
                        </div>
                        <div className="element-description">
                            <p id="element-header">
                                AIR / GEMINI, LIBRA, AQUARIUS
                            </p>
                            <p id="element-text">
                                Air is mobile. It moves. Air signs shift ideas
                                and discussion. Gemini, Libra and Aquarius are a
                                breath of fresh air. They breathe new life into
                                situations. They can also whip up storms and
                                hurricanes. Air signs make waves with the water
                                signs – Cancer, Scorpio, Pisces. They can
                                scatter them. They can uproot the earth signs –
                                Taurus, Virgo, Capricorn. Air signs feed the
                                fire signs – Aries, Leo, Sagittarius. They
                                change direction all the time and are in
                                constant motion. Air signs sway opinion. In
                                Tarot, Air is shown by the Swords suit.
                            </p>
                        </div>
                        <div className="element-description">
                            <p id="element-header">
                                WATER / CANCER, SCORPIO, PISCES
                            </p>
                            <p id="element-text">
                                Water signs are emotional. They easily cry tears
                                of joy or sadness. They have no boundaries, like
                                the ocean, and merge with their partners,
                                friends and relatives. When two water signs are
                                in a sexual relationship, a solutio occurs. This
                                is an old alchemical term for immersion. The
                                water signs can feel all at sea and need the
                                earth signs – Taurus, Virgo and Capricorn – to
                                anchor them. They do not live in the real world.
                                They live in their alternative world. In the
                                Tarot, water signs are shown by the suit of
                                Cups.
                            </p>
                        </div>
                    </div>
                )}
                <p
                    id="btn"
                    onClick={() => {
                        btnHandle();
                    }}
                >
                    {btnText}
                </p>
            </div>
        </div>
    );
};

export default Elements;
