// Libraries import
import React, { useState } from "react";
import { useEffect, useRef } from "react";

// Styling import
import "./Main.scss";

// Components import
import Header from "../Header/Header";
import NFTs from "../NFTs/NFTs";
import Elements from "../Elements/Elements";
import Explanation from "../Explanation/Explanation";
import Team from "../Team/Team";
import FAQs from "../FAQs/FAQs";
import Footer from "../Footer/Footer";
import Mint from "../Mint/Mint";

const Main = () => {
    const [width, setWidth] = useState(window.innerWidth); // Variable for tracking window width
    const [isHorizontal, setIsHorizontal] = useState(false); // Variable for tracking horizontal layout

    // Function for tracking window width
    useEffect(() => {
        const handleWindowResize = () => {
            setWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleWindowResize);

        return () => {
            window.removeEventListener("resize", handleWindowResize);
        };
    }, []);

    // Function for tracking horizontal layout
    useEffect(() => {
        const checkOrientation = () => {
            setIsHorizontal(window.innerWidth > window.innerHeight);
        };

        checkOrientation();

        window.addEventListener("resize", checkOrientation);

        return () => {
            window.removeEventListener("resize", checkOrientation);
        };
    }, []);

    // Scroll to top of page
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    // useRef values
    const mint = useRef(null);
    const nfts = useRef(null);
    const elements = useRef(null);
    const explanation = useRef(null);
    const team = useRef(null);
    const faqs = useRef(null);

    // Autoscrolling function
    const scrollToSection = (ref: React.MutableRefObject<any>) => {
        window.scrollTo({
            top: ref.current.offsetTop,
            behavior: "smooth",
        });
    };

    return (
        <div className="Main">
            <Header
                isHorizontal={isHorizontal}
                width={width}
                scrollToSection={scrollToSection}
                mint={mint}
                nfts={nfts}
                elements={elements}
                explanation={explanation}
                team={team}
                faqs={faqs}
            />
            <Mint width={width} mint={mint} />
            <NFTs nfts={nfts} />
            <Elements elements={elements} />
            <Explanation explanation={explanation} />
            <Team team={team} width={width} />
            <FAQs faqs={faqs} width={width} />
            <Footer width={width} />
        </div>
    );
};

export default Main;
