// Libraries import
import React from 'react';
import { FC } from 'react';

// Styling import
import './NFTs.scss';
import fire_nft from '../../resources/png/nfts/fire-nft.png';
import air_nft from '../../resources/png/nfts/air-nft.png';
import earth_nft from '../../resources/png/nfts/earth-nft.png';
import water_nft from '../../resources/png/nfts/water-nft.png';

// Components import
import { nftsProps } from '../../utils/props';

const NFTs: FC<nftsProps> = ({nfts}) => {
  return(
    <div className="NFTs" ref={nfts}>
      <p id="header">The NFTS</p>
      <div className="container">
        <div className="nfts">
          <div className="nft">
            <img src={fire_nft} alt="Fire NFT" />
          </div>
          <div className="nft">
            <img src={air_nft} alt="Air NFT" />
          </div>
          <div className="nft">
            <img src={earth_nft} alt="Earth NFT" />
          </div>
          <div className="nft">
            <img src={water_nft} alt="Waters NFT" />
          </div>
        </div>
        <p id="description">
          Enjoy the Elementals, 256 NFTs - 64 of each element. Colours vary, each unique with surprising visitors popping up like a scurrying scorpion, a lovely lion, funny fish, love birds and more. Limited edition - but some rarer than others! 
          <br /> 
          Soft Launched July 14 2023 🚀
        </p>
      </div>
    </div>
  );
};

export default NFTs;