// Libraries import
import React, { useState } from "react";
import { FC } from "react";
import { isMobile } from "react-device-detect";

// Styling import
import "./Header.scss";
import open from "../../resources/png/menu/open-menu.png";
import close from "../../resources/png/menu/close-menu.png";

// Components import
import { headerProps } from "../../utils/props";
import { Link } from "react-router-dom";

const Header: FC<headerProps> = ({
    isHorizontal,
    width,
    scrollToSection,
    mint,
    nfts,
    elements,
    explanation,
    team,
    faqs,
}) => {
    const [visible, setVisible] = useState(false);

    return (
        <div className="Header">
            {!isMobile && width > 1130 ? (
                <div className="web-container">
                    <div
                        className="btn"
                        onClick={() => {
                            scrollToSection(mint);
                        }}
                    >
                        <p>Mint</p>
                    </div>
                    <div
                        className="btn"
                        onClick={() => {
                            scrollToSection(nfts);
                        }}
                    >
                        <p>NFTs</p>
                    </div>
                    <div
                        className="btn"
                        onClick={() => {
                            scrollToSection(elements);
                        }}
                    >
                        <p>Elements</p>
                    </div>
                    <div
                        className="btn"
                        onClick={() => {
                            scrollToSection(explanation);
                        }}
                    >
                        <p>Story</p>
                    </div>
                    <div
                        className="btn"
                        onClick={() => {
                            scrollToSection(team);
                        }}
                    >
                        <p>Who</p>
                    </div>
                    <div
                        className="btn"
                        onClick={() => {
                            scrollToSection(faqs);
                        }}
                    >
                        <p>FAQs</p>
                    </div>
                    <Link to={"/how"}>
                        <div className="btn">
                            <p>How to buy</p>
                        </div>
                    </Link>
                </div>
            ) : (
                <div className="mobile-container">
                    <div className="header">
                        {!visible ? (
                            <img
                                src={open}
                                id="menu"
                                onClick={() => {
                                    setVisible(!visible);
                                }}
                            />
                        ) : (
                            <img
                                src={close}
                                id="menu"
                                onClick={() => {
                                    setVisible(!visible);
                                }}
                            />
                        )}
                        <p id="logo">elementals</p>
                    </div>
                    {visible && (
                        <div
                            className={`options ${
                                isHorizontal ? "horizontal" : ""
                            }`}
                        >
                            {" "}
                            <div
                                className="option"
                                onClick={() => {
                                    scrollToSection(mint);
                                    setVisible(!visible);
                                }}
                            >
                                <p>Mint</p>
                            </div>
                            <div
                                className="option"
                                onClick={() => {
                                    scrollToSection(nfts);
                                    setVisible(!visible);
                                }}
                            >
                                <p>NFTs</p>
                            </div>
                            <div
                                className="option"
                                onClick={() => {
                                    scrollToSection(elements);
                                    setVisible(!visible);
                                }}
                            >
                                <p>Elements</p>
                            </div>
                            <div
                                className="option"
                                onClick={() => {
                                    scrollToSection(explanation);
                                    setVisible(!visible);
                                }}
                            >
                                <p>Story</p>
                            </div>
                            <div
                                className="option"
                                onClick={() => {
                                    scrollToSection(team);
                                    setVisible(!visible);
                                }}
                            >
                                <p>Who</p>
                            </div>
                            <div
                                className="option"
                                onClick={() => {
                                    scrollToSection(faqs);
                                    setVisible(!visible);
                                }}
                            >
                                <p>FAQs</p>
                            </div>
                            <div className="option">
                                <Link to={"/how"} id="link">
                                    How to buy
                                </Link>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default Header;
