// Libraries import
import React, { useEffect } from "react";

// Styling import
import "./HowTo.scss";
import { Link } from "react-router-dom";

// Components import

const HowTo = () => {
    // Scroll to top of page
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="HowTo">
            <p id="header">New to NFTs? Start here!</p>
            <div className="container">
                <div className="context">
                    <ol>
                        <li>
                            <p id="content">
                                Think of an NFT as digital art— a collector's
                                item that is unique and belongs to you, once
                                you've bought it. Like all art, it may increase
                                in value. <br />
                                <br />
                            </p>
                        </li>
                        <li>
                            <p id="content">
                                Each NFT in the Elementals Astrology NFT collection costs: <br />
                                <br />
                                18.5 AVAX / $240 USD / 220 EURO / 188 GBP / 360 AUD (as of 5 July 2023 with an AVAX price of $13). <br />
                                <br />
                                Payment is denominated in $AVAX, a cryptocurrency like Ether or Bitcoin. But remember that $AVAX can fluctuate. Your browser wallet can automatically convert $AVAX from your credit card so you do not need to buy it in advance. <br />
                                <br />
                            </p>
                        </li>
                        <li>
                            <p id="content">
                                To buy your unique NFT, you will need a digital
                                browser wallet called Metamask.{" "}
                                <Link id="link_2" to={"/wallet"}>
                                    Instructions here.
                                </Link>
                                <br />
                                <br />
                            </p>
                        </li>
                        <li>
                            <p id="content">HOW TO MINT AN NFT<br />
                            Read below or {" "} <Link id="link_2" to={"https://youtu.be/8T8LvASY2J8"}>
                            watch this 11 minute video
                            </Link>
                            </p>
                            <ol type="a">
                                <li>
                                    <p id="content">
                                        Click “Connect” on the top right
                                        <br /> <br />
                                    </p>
                                </li>
                                <li>
                                    <p id="content">
                                        Allow your browser wallet to be
                                        connected
                                        <br /> <br />
                                    </p>
                                </li>
                                <li>
                                    <p id="content">
                                        Allow Metamask to switch to Avalanche
                                        Network. (You can check you are on
                                        Avalanche network by clicking “Network”
                                        at the top of your wallet. If it is not
                                        there click “Add a Network” and then
                                        click on “Avalanche”)
                                        <br /> <br />
                                    </p>
                                </li>
                                <li>
                                    <p id="content">
                                        On the website click the MINT button
                                        <br /> <br />
                                    </p>
                                </li>
                                <li>
                                    <p id="content">
                                    Metamask will allow you to use AVAX or credit card. 
                                    <br />
                                    When using your credit card keep in mind that for most people this counts as a foreign transaction and you might get an alert from your bank asking you to confirm the payment - similar to when you make an unusual online transaction or go on holiday overseas. 
                                    <br /> <br />
                                    </p>
                                </li>
                                <li>
                                    <p id="content">
                                        Your NFT can be viewed in your wallet under the tab “NFTs” - it can sometimes take time to show up. Alternatively, go to https://opensea.io/ and connect your wallet and click on "My Collections" where you will see your NFT!
                                        <br /> <br />
                                        Feel free to share on our Twitter!
                                    </p>{" "}
                                    <br />
                                    <br />
                                </li>
                            </ol>
                        </li>
                        <li>
                            <p id="content">
                                Buying one NFT allows you exclusive access into
                                one of four Zoom events with astrologer Jessica
                                Adams in October 2023, hosted by Alicia Fulton
                                from The Astrology Show. You will also be
                                rewarded with a free NFT drop in 2024! <br />
                                <br />
                            </p>
                        </li>
                        <li>
                            <p id="content">
                                Each event is for one element group: Fire
                                (Aries, Leo, Sagittarius), Earth (Taurus, Virgo,
                                Capricorn), Air (Gemini, Libra, Aquarius) or
                                Water (Cancer, Scorpio, Pisces). In these
                                exclusive events Jessica will explain how to use
                                your element and live your happiest life with
                                it. Once we have your email we will invite you
                                with a link. <br />
                                <br />
                            </p>
                        </li>
                        <li>
                            <p id="content">
                                You can't copy your NFT onto a T-Shirt
                                or reproduce it on a mousepad, but you can use
                                it as a screensaver, your social media PFP, or
                                display it in a digital art frame. You own it
                                forever unless you decide to sell it. You can
                                sell at anytime for a price of your choosing. <br />
                                <br />
                            </p>
                        </li>
                        <li>
                            <p id="content">
                                Finally - If you are new to NFTs and Cryptocurrency please make sure you read through our FAQs for information and advice. Note that some banks might automatically decline cryptocurrency transactions, especially if it is a first purchase. If in doubt, it's good practice to contact your bank in advance to inform them you will be buying, in the same way you would tell your bank if you're going to be traveling abroad. With many banks it's also possible to simply verify these purchases through a mobile phone app.
                            </p>
                        </li>
                    </ol>
                </div>
                <Link id="link-tag" to={"/"}>
                    <div className="btn">
                        <p id="link">Back to main page</p>
                    </div>
                </Link>
            </div>
        </div>
    );
};

export default HowTo;
