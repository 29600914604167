// Libraries import
import React from "react";
import { FC } from "react";
import { Link } from "react-router-dom";

// Styling import
import "./Team.scss";
import jessica from "../../resources/png/jessica.png";
import anita from "../../resources/png/anita.png";
import tintin from "../../resources/png/tintin.png";

// Components import
import { teamProps } from "../../utils/props";
import { isMobile } from "react-device-detect";

const Team: FC<teamProps> = ({ team, width }) => {
    return (
        <div className="Team" ref={team}>
            <p id="header">The Team behind elementals nfts</p>
            <div className="container">
                {!isMobile && width > 1000 ? (
                    <div className="members">
                        <div className="member">
                            <p id="about">
                                Jessica Adams (Leo) is the author of “Essential
                                Astrology for Women” (HarperCollins) and
                                “Handbag Horoscopes” (Penguin). She is the host
                                of The Astrology Show Podcast, the number one
                                Apple Spirituality Chart program.{" "}
                                <Link
                                    id="link"
                                    to={"http://jessicaadams.com/"}
                                    target="_blank"
                                >
                                    More about Jessica
                                </Link>
                            </p>
                            <img src={jessica} alt="Jessica Adams" />
                        </div>
                        <div className="member">
                            <p id="about">
                                Anita Bacic (Aries) is an Australian artist and
                                designer working at the intersection of digital
                                technology, place and movement.{" "}
                                <Link
                                    id="link"
                                    to={"https://anitabacic.com/"}
                                    target="_blank"
                                >
                                    More about Anita
                                </Link>
                            </p>
                            <img src={anita} alt="Anita Bacic" />
                        </div>
                        <div className="member">
                            <p id="about">
                                Tintin Cooper (Gemini) is a Thai-English artist
                                and first created NFTs in 2015 on the Bitcoin
                                blockchain with NFT pioneers Ascribe.io and
                                Cointemporary.{" "}
                                <Link
                                    id="link"
                                    to={"https://www.tintincooper.com/"}
                                    target="_blank"
                                >
                                    More about Tintin
                                </Link>
                            </p>
                            <img src={tintin} alt="Tintin Cooper" />
                        </div>
                    </div>
                ) : (
                    <div className="members">
                        <div className="member">
                            <img src={jessica} alt="Jessica Adams" />
                            <p id="name">Jessica Adams</p>
                            <p id="about">
                                Jessica Adams (Leo) is the author of “Essential
                                Astrology for Women” (HarperCollins) and
                                “Handbag Horoscopes” (Penguin). She is the host
                                of The Astrology Show Podcast, the number one
                                Apple Spirituality Chart program.{" "}
                                <Link
                                    id="link"
                                    to={"http://jessicaadams.com/"}
                                    target="_blank"
                                >
                                    More about Jessica
                                </Link>
                            </p>
                        </div>
                        <div className="member">
                            <img src={anita} alt="Anita Bacic" />
                            <p id="name">Anita Bacic</p>
                            <p id="about">
                                Anita Bacic (Aries) is an Australian artist and
                                designer working at the intersection of digital
                                technology, place and movement.{" "}
                                <Link
                                    id="link"
                                    to={"https://anitabacic.com/"}
                                    target="_blank"
                                >
                                    More about Anita
                                </Link>
                            </p>
                        </div>
                        <div className="member">
                            <img src={tintin} alt="Tintin Cooper" />
                            <p id="name">Tintin Cooper</p>
                            <p id="about">
                                Tintin Cooper (Gemini) is a Thai-English artist
                                and first created NFTs in 2015 on the Bitcoin
                                blockchain with NFT pioneers Ascribe.io and
                                Cointemporary.{" "}
                                <Link
                                    id="link"
                                    to={"https://www.tintincooper.com/"}
                                    target="_blank"
                                >
                                    More about Tintin
                                </Link>
                            </p>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Team;
