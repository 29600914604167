import React from "react";

const normalWallet: React.CSSProperties = {
    background: "#F9902F",
    border: "0.125rem solid rgb(202, 98, 1)",
};

const connectedWallet: React.CSSProperties = {
    background: "#00B111",
    border: "0.125rem solid rgb(0, 96, 13)",
    pointerEvents: "none",
};

export { normalWallet, connectedWallet };
