// Libraries import
import React, { FC } from "react";
import { AiOutlineTwitter } from "react-icons/ai";
import { Link } from "react-router-dom";
import { footerProps } from "../../utils/props";
import { isMobile } from "react-device-detect";

// Styling import
import "./Footer.scss";

// Components import

const Footer: FC<footerProps> = ({ width }) => {
    return (
        <div className="Footer">
            <div className="container">
                <Link
                    id="link twitter"
                    to={"http://twitter.com/astroelementals"}
                    target="_blank"
                >
                    <AiOutlineTwitter id="twitter" color="#FFFFFF" size="50" />
                </Link>
                {!isMobile && width > 860 ? (
                    <p id="trade-mark">
                        © 2023 Elementals: Astrology - Jessica Adams, Anita
                        Bacic & Tintin Cooper
                    </p>
                ) : (
                    <p id="trade-mark" style={{ textAlign: "center" }}>
                        © 2023 Elementals: Astrology
                        <br />
                        Jessica Adams, Anita Bacic & Tintin Cooper
                    </p>
                )}
                <div className="links">
                    <Link
                        id="link"
                        to={"https://www.9320.tech/en/"}
                        target="_blank"
                    >
                        Web Development
                    </Link>
                    <Link id="link" to={"/terms"}>
                        Terms & Conditions
                    </Link>
                    <Link id="link" to={"/policy"}>
                        Privacy Policy
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default Footer;
