import axios from "axios";
import { error, success } from "./toasts";

import { BiErrorAlt, BiServer, BiWallet } from "react-icons/bi";
import { MdOutlineDoneOutline } from "react-icons/md";
import { ethers } from "ethers";

import ELM_ABI from "../resources/ELM_ABI.json";

// Call backend for tokenID
const mint = async (
    window: Window & typeof globalThis,
    setNFTImage: React.Dispatch<React.SetStateAction<string>>,
    setIsMinted: React.Dispatch<React.SetStateAction<boolean>>
) => {
    try {
        const response = await axios.get(
            `${process.env.REACT_APP_BACKEND_URL}mint`
        );

        if (response.data.status === "Error") {
            error(response.data.message, BiServer);
        } else {
            const metamask = new ethers.BrowserProvider(window.ethereum!);
            let signer = await metamask.getSigner();

            const contract = new ethers.Contract(
                process.env.REACT_APP_ELM_CONTRACT_AVALANCHE_ADDRESS!,
                ELM_ABI.abi,
                signer
            );

            const isWhitelisted = await contract.isAddressWhitlisted(
                await signer.getAddress()
            );

            const price = ethers.parseEther(isWhitelisted ? "15.5" : "18.5");
            const limit = 150000;

            // Mint token
            await contract.publicMint(response.data.tokenID, {
                value: price,
                gasLimit: limit,
            });

            // Check if token ID has been minted
            await contract.ownerOf(response.data.tokenID);

            const json = await axios.get(
                `https://ipfs.io/ipfs/bafybeieq2mvnqlqrq7t6ffvysc5zl5xnvcw2nb5lwzr2tkfovbbxy4yxcm/${response.data.tokenID}.json`
            );
            setNFTImage(`https://ipfs.io/ipfs/${json.data.image.substring(7)}`);

            setIsMinted(true);

            success(
                `You have minted the NFT #${response.data.tokenID} to your wallet!`,
                MdOutlineDoneOutline
            );
        }
    } catch (err) {
        if ((err as any).reason === "ERC721: invalid token ID") {
            error("Transaction still hasn't gone through!", BiWallet);
        } else if ((err as any).code !== "ACTION_REJECTED") {
            console.error(err);
            error(
                "An error occured while trying to ming! Please contact the page administrators",
                BiErrorAlt
            );
        } else {
            error(
                "You must confirm the minting process in order to proceed!",
                BiWallet
            );
        }
    }
};

export { mint };
