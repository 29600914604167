import { Provider, ethers } from "ethers";
import validator from "validator";
import { error } from "./toasts";

import {
    AiOutlineMail,
    AiOutlineReload,
    AiOutlineWallet,
} from "react-icons/ai";
import { MdOutlinePolicy, MdOutlineReportGmailerrorred } from "react-icons/md";
import { IoMdGitNetwork } from "react-icons/io";
import { GiBreakingChain } from "react-icons/gi";

import { addDoc, collection } from "firebase/firestore";
import { db } from "./firebase";
import { connectedWallet, normalWallet } from "../resources/values";
import { mint } from "./mint";
import { isMobile } from "react-device-detect";

const newsletterHandler = async (
    wallet: string,
    newsletter: boolean,
    email: string,
    policy: boolean
): Promise<boolean> => {
    // Check if wallet is valid
    if (!ethers.isAddress(wallet)) {
        error("Invalid wallet address!", AiOutlineWallet);
        return false;
    }

    // Check if email checked
    if (newsletter) {
        // Check if email is valid
        if (!validator.isEmail(email)) {
            error("Invalid email address!", AiOutlineMail);
            return false;
        }
    }

    // Check if policy accepted
    if (!policy) {
        error("Please read and accept the privacy policy!", MdOutlinePolicy);
        return false;
    }

    // All good
    try {
        const docRef = await addDoc(collection(db, "newsletters"), {
            time: new Date(),
            wallet: wallet,
            email: email,
        });

        if (docRef) {
            return true;
        }
    } catch (e) {
        error(
            "An error occured during the request!",
            MdOutlineReportGmailerrorred
        );
        console.log(`Error adding new document: ${e}`);
        return false;
    }

    return false;
};

const handleNetworkChange = (
    window: Window & typeof globalThis,
    setText: React.Dispatch<React.SetStateAction<string>>,
    setProvider: React.Dispatch<React.SetStateAction<Provider | undefined>>,
    setWalletStyle: React.Dispatch<React.SetStateAction<React.CSSProperties>>
) => {
    if (window.ethereum && window.ethereum.isMetaMask) {
        window.ethereum.on("chainChanged", async () => {
            window
                .ethereum!.request({ method: "eth_chainId" })
                .then(async (chainId: any) => {
                    if (chainId === "0xa86a") {
                        setText("connected");
                        setWalletStyle(connectedWallet);
                        setProvider(
                            new ethers.BrowserProvider(window.ethereum!)
                        );
                    } else {
                        setText("connect");
                        setWalletStyle(normalWallet);
                        error(
                            "Reloading page due to network change! Try again after the page loads",
                            AiOutlineReload
                        );
                        await wait(5);
                        window.location.reload();
                    }
                });
        });
    } else {
        setWalletStyle(normalWallet);
        if (isMobile) {
            error("Please enter the website via Metamask!", AiOutlineWallet);
        } else {
            error("Please install metamask!", AiOutlineWallet);
        }
        return;
    }
};

const handlePageLoad = (
    window: Window & typeof globalThis,
    setText: React.Dispatch<React.SetStateAction<string>>,
    setProvider: React.Dispatch<React.SetStateAction<Provider | undefined>>,
    setWalletStyle: React.Dispatch<React.SetStateAction<React.CSSProperties>>
) => {
    if (window.ethereum && window.ethereum.isMetaMask) {
        window.ethereum
            .request({ method: "eth_accounts" })
            .then((accounts: any) => {
                if (accounts.length) {
                    window
                        .ethereum!.request({ method: "eth_chainId" })
                        .then((chainId: any) => {
                            if (chainId === "0xa86a") {
                                setText("connected");
                                setWalletStyle(connectedWallet);
                                setProvider(
                                    new ethers.BrowserProvider(window.ethereum!)
                                );
                            } else {
                                setText("connect");
                                setWalletStyle(normalWallet);
                            }
                        });
                } else {
                    setText("connect");
                    setWalletStyle(normalWallet);
                }
            })
            .catch((err) => {
                setText("connect");
                setWalletStyle(normalWallet);
                error(
                    "Error occured while connecting to wallet",
                    AiOutlineWallet
                );
                console.error(err);
            });
    } else {
        setText("connect");
        setWalletStyle(normalWallet);
    }
};

const handleWalletConnect = async (
    window: Window & typeof globalThis,
    setText: React.Dispatch<React.SetStateAction<string>>,
    setProvider: React.Dispatch<React.SetStateAction<Provider | undefined>>,
    setWalletStyle: React.Dispatch<React.SetStateAction<React.CSSProperties>>
) => {
    if (!window.ethereum) {
        setText("connect");
        setWalletStyle(normalWallet);
        if (isMobile) {
            error("Please enter the website via Metamask!", AiOutlineWallet);
        } else {
            error("Please install metamask!", AiOutlineWallet);
        }

        return;
    }

    try {
        await window.ethereum.request({ method: "eth_requestAccounts" });
        try {
            await window.ethereum.request({
                method: "wallet_switchEthereumChain",
                params: [{ chainId: "0xa86a" }],
            });
        } catch (err) {
            if ((err as any).code === 4902) {
                try {
                    await window.ethereum.request({
                        method: "wallet_addEthereumChain",
                        params: [
                            {
                                chainId: "0xa86a",
                                rpcUrls: [
                                    "https://api.avax.network/ext/bc/C/rpc",
                                ],
                                chainName: "Avalanche",
                                nativeCurrency: {
                                    name: "Avalanche",
                                    symbol: "AVAX",
                                    decimals: 18,
                                },
                                blockExplorerUrls: ["https://snowtrace.io"],
                            },
                        ],
                    });
                } catch (err) {
                    setText("connect");
                    setWalletStyle(normalWallet);
                    error(
                        "Unable to add the Avalanche network!",
                        GiBreakingChain
                    );
                    return;
                }
            } else {
                setText("connect");
                setWalletStyle(normalWallet);
                error(
                    "Please switch to the Avalanche network!",
                    IoMdGitNetwork
                );
                return;
            }
        }
        setText("connected");
        setWalletStyle(connectedWallet);
        setProvider(new ethers.BrowserProvider(window.ethereum!));
    } catch (err) {
        error("Please connect your metamask wallet!", AiOutlineWallet);
        setText("connect");
        setWalletStyle(normalWallet);
        return;
    }
};

const handleMint = async (
    window: Window & typeof globalThis,
    setText: React.Dispatch<React.SetStateAction<string>>,
    setWalletStyle: React.Dispatch<React.SetStateAction<React.CSSProperties>>,
    setIsMinted: React.Dispatch<React.SetStateAction<boolean>>,
    setNFTImage: React.Dispatch<React.SetStateAction<string>>
) => {
    if (window.ethereum && window.ethereum.isMetaMask) {
        window.ethereum
            .request({ method: "eth_accounts" })
            .then(async (accounts: any) => {
                if (accounts.length) {
                    window
                        .ethereum!.request({ method: "eth_chainId" })
                        .then(async (chainId: any) => {
                            if (chainId !== "0xa86a") {
                                setText("connect");
                                setWalletStyle(normalWallet);
                                error(
                                    "Please switch to Avalnche! Reloading page",
                                    AiOutlineReload
                                );
                                await wait(5);
                                window.location.reload();
                                return false;
                            } else {
                                await mint(window, setNFTImage, setIsMinted);
                            }
                        });
                } else {
                    setText("connect");
                    setWalletStyle(normalWallet);
                    error(
                        "Please connect to your wallet first! Reloading page...",
                        AiOutlineReload
                    );
                    await wait(5);
                    window.location.reload();
                    return false;
                }
            })
            .catch(async (err) => {
                setText("connect");
                setWalletStyle(normalWallet);
                error(
                    "An error occured while retrieving data from Metamask. Reloading page",
                    AiOutlineReload
                );
                console.error(err);
                await wait(5);
                window.location.reload();
                return false;
            });
    } else {
        setText("connect");
        setWalletStyle(normalWallet);
        error("Please first install Metamask!", AiOutlineReload);
        await wait(5);
        window.location.reload();
        return false;
    }
};

const wait = async (seconds: number) => {
    return new Promise((resolve) => setTimeout(resolve, seconds * 1000));
};

export {
    newsletterHandler,
    handleNetworkChange,
    handlePageLoad,
    handleWalletConnect,
    handleMint,
    wait,
};
