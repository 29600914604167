// Libraries import
import React from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";

// Styling import
import "./Wallet.scss";
import banner from "../../resources/png/icons/banner-image.png";

// Components import

const Wallet = () => {
    // Scroll to top of page
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="Wallet">
            <p id="header">
                How to Get a Metamask Wallet <br />& buy Elementals NFTs!
            </p>
            <div className="container">
                <img src={banner} alt="Banner" />
                <div className="welcome central">
                    <p id="heading">Welcome to the world of Elementals NFTs!</p>
                    <p id="content">
                        This step-by-step guide is designed for total newbies
                        who do not have a cryptocurrency wallet installed in
                        their web browser.
                        <br /> <br />
                        Here we walk you through installing MetaMask into your
                        browser, so you will be able to register your wallet (if
                        you don't have one), and purchase an Elemental NFT.
                        <br /> <br />
                        The whole process should take no more than 3-5 minutes.
                        <br /> <br />
                    </p>
                </div>
                <div className="step_1 central">
                    <p id="heading">
                        Step 1: Install MetaMask Wallet (2-5 minutes)
                    </p>
                    <ul>
                        <li>
                            <p id="content">
                                Go to{" "}
                                <Link
                                    id="link"
                                    to={"https://metamask.io/download/"}
                                    target={"_blank"}
                                >
                                    https://metamask.io/download/
                                </Link>
                            </p>
                        </li>
                        <li>
                            <p id="content">
                                Click “Install”. Metamask will automatically
                                install for your browser.
                            </p>
                        </li>
                        <li>
                            <p id="content">
                                Follow the instructions provided by MetaMask to
                                add the extension.
                            </p>
                        </li>
                        <li>
                            <p id="content">
                                Once installed, click on the MetaMask extension
                                icon in your browser. Usually, you will see the
                                “fox” icon in your toolbar - if not click on the
                                puzzle symbol on the right of the toolbar and
                                Metamask will be there.
                            </p>
                        </li>
                        <li>
                            <p id="content">
                                Now follow Metamask's prompts to create a new
                                wallet.
                            </p>
                        </li>
                    </ul>
                    <br />
                    <p id="content">Notes*</p>
                    <p id="content">
                        Remember to write down the seed phrase and keep it in a
                        safe place — this is how you will be able to retrieve
                        your wallet in case something happens to your computer.
                        <br />
                        Video walk-through guide:{" "}
                        <Link
                            id="link"
                            to={"https://www.youtube.com/watch?v=-HTubEJ61zU"}
                            target={"_blank"}
                        >
                            https://www.youtube.com/watch?v=-HTubEJ61zU
                        </Link>
                    </p>
                    <br />
                </div>
                <div className="step_2 central">
                    <p id="heading">
                        Step 2: Find Your Wallet Address (10 secs)
                    </p>
                    <p id="content">
                        While logged into your Metamask wallet, you will see you
                        wallet address listed directly under your account. Click
                        the copy button to copy the address.
                    </p>
                    <br /> <br />
                </div>
                <div className="step_3 central">
                    <p id="heading">
                        Step 3: Buy an Elementals NFT (15 seconds)
                    </p>
                    <ul>
                        <li>
                            <p id="content">
                                More instructions on how to buy here:{" "}
                                <Link id="link" to={"/how"}>
                                    https://elementalsnfts.com/how/
                                </Link>
                            </p>
                        </li>
                        <li>
                            <p id="content">
                                Or visit the Elementals NFT website at:{" "}
                                <Link id="link" to={"/"}>
                                    https://elementalsnfts.com/
                                </Link>
                            </p>
                        </li>
                        <li>
                            <p id="content">And click on the “MINT” button.</p>
                        </li>
                    </ul>
                    <br /> <br />
                    <p id="heading">
                        Looking forward to sharing Elementals NFTs in JULY 2023!
                    </p>
                    <br /> <br />
                    <p id="content">
                        Follow our{" "}
                        <Link
                            id="link"
                            to={"https://twitter.com/AstroElementals"}
                            target={"_blank"}
                        >
                            Twitter
                        </Link>{" "}
                        for updates and reminders!
                    </p>
                </div>
                <div className="btn">
                    <Link id="link" to={"/"}>
                        Back to main page
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default Wallet;
