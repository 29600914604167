// Libraries import
import React from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";

// Styling import
import "./Policy.scss";

// Components import

const Policy = () => {
    // Scroll to top of page
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="Policy">
            <p id="header">
                Elementals' Privacy Policy
            </p>
            <div className="container">
                <p id="intro" className="central">
                    Welcome to Elementals' Privacy Policy. We are committed to
                    respecting your privacy. <br /> <br />
                    This Privacy Policy applies to all of our operations. It was
                    last updated on 28 June 2023. We may update it from time to
                    time and will post any revised policy on this web page.
                    <br /> <br />
                    It is important to us that the personal information we hold
                    about you is accurate and current. Please keep us informed
                    if your personal information changes during your
                    relationship with us.
                    <br /> <br />
                    For further information about our Privacy Policy or
                    practices, or to access or correct your personal
                    information, or make a complaint, please contact our Privacy
                    Officer at info@elementalsnfts.com
                    <br /> <br />
                </p>
                <div className="personal central">
                <br /> <br />
                <p id="heading">
                    1. Who are we?
                    </p>
                    <p id="content">
                        In this Privacy Policy, ‘us’, ‘we’, ‘our’ and
                        ‘Elementals’ are used to refer to collectively refer to
                        astrologer Jessica Adams (ACN 55 851 319 538), artist
                        and designer Anita Bacic (ABN 17 405 461 579) and artist
                        Tintin Cooper, jointly and severally.
                    </p>
                    <br /> <br />
                </div>
                <div className="non-personal central">
                    <p id="heading">
                        2. What personal information do we collect?
                    </p>
                    <p id="content">
                        We only collect personal information for purposes which
                        are directly related to our services and activities,
                        such as providing you with details about our Elementals:
                        Astrology NFTs (Elementals NFTs).
                        <br /> <br />
                        We may collect the following types of personal
                        information:
                        <ul>
                            <li>
                                <p id="content">
                                    contact details, including first name, last
                                    name and email address;
                                </p>
                            </li>
                            <li>
                                <p id="content">
                                    your device ID, device type, geo-location
                                    information, statistics on page views,
                                    traffic to and from our website, ad data, IP
                                    address and standard web log information;
                                    and
                                </p>
                            </li>
                            <li>
                                <p id="content">
                                    any other personal information that may be
                                    required in order to facilitate your
                                    dealings with us.
                                </p>
                            </li>
                        </ul>
                    </p>
                    <br /> <br />
                </div>
                <div className="data-collection central">
                    <p id="heading">
                        3. When do we collect personal information?
                    </p>
                    <p id="content">
                        We may collect these types of personal information
                        directly from you when you:
                    </p>
                    <ul>
                        <li>
                            <p id="content">
                                subscribe to receive news and updates about
                                Elementals;
                            </p>
                        </li>
                        <li>
                            <p id="content">
                                when you buy, trade or transfer an Elementals
                                NFT;
                            </p>
                        </li>
                        <li>
                            <p id="content">
                                communicate with us through any form of
                                correspondence, chats, email, our website or
                                when you share information with us from social
                                media platforms, applications, or websites; or
                            </p>
                        </li>
                        <li>
                            <p id="content">
                                interact with our website, services, content and
                                advertising.
                            </p>
                        </li>
                    </ul>
                    <p id="content">
                        We may collect these types of personal information from
                        third parties, such as:
                        <ul>
                            <li>
                                <p id="content">
                                a person or organisation who facilitates an
                                introduction from us to you.
                                </p>
                            </li>
                        </ul>
                    </p>
                    <br /> <br />
                </div>
                <div className="personal-info central">
                <p id="heading">
                    4. Why do we collect personal information?
                </p>
                    <p id="content">
                        We may collect, hold, use and disclose your personal information for the following purposes:
                        <ul>
                            <li>
                                <p id="content">
                                    to receive news and updates about Elementals (with your consent);
                                </p>
                            </li>
                            <li>
                                <p id="content">
                                    to engage with you where you are a current or potential investor in Elementals;
                                </p>
                            </li>
                            <li>
                                <p id="content">
                                    to enable you to purchase, trade and transfer Elementals NFTs;
                                </p>
                            </li>
                            <li>
                                <p id="content">
                                    to manage and develop our relationship with you, including sending you information requested by you;
                                </p>
                            </li>
                            <li>
                                <p id="content">
                                    to operate, protect and optimise our business, website and social media accounts (including troubleshooting, data analysis, users’ experience, testing, system maintenance, supporter, reporting); 
                                </p>
                            </li>
                            <li>
                                <p id="content">
                                    to keep you and our staff secure;
                                </p>
                            </li>
                            <li>
                                <p id="content">
                                    to collect and recover money (in fiat currency or cryptocurrency) owed to us; and
                                </p>
                            </li>
                            <li>
                                <p id="content">
                                    to comply with our legal and regulatory obligations, including to resolve any disputes that we may have with any of our users, and enforce our agreements with third parties.
                                </p>
                            </li>
                        </ul>
                        <br />
                    </p>
                </div>
                <div className="direct-marketing central">
                    <p id="heading">
                        5. Do we use your personal information for direct marketing?
                    </p>
                    <p id="content">
                        Making you aware of carefully selected information about Elementals is an integral part of our services. We may send you direct marketing communications in the form of emails, and other forms of communication.
                        <br /> <br />
                        You may opt-out of receiving such communications from us by contacting us using the details set out below or by using the opt-out facilities provided (e.g. an unsubscribe link). 
                    </p>
                    <br /> <br />
                </div>
                <div className="protection central">
                    <p id="heading">
                        6. To whom do we disclose your personal information?
                    </p>
                    <p id="content">
                        We do not transfer your personal information to organisations who wish to use it for their own marketing promotions or other purposes.
                        <br /> <br />
                        We may disclose your personal information for the purposes described in this Privacy Policy to:
                        <ul>
                            <li>
                                <p id="content">
                                    third party suppliers and service providers, including cryptocurrency wallet providers, NFT aggregator and blockchain transaction platforms;
                                </p>
                            </li>
                            <li>
                                <p id="content">
                                    anyone to whom our assets or businesses (or any part of them) are transferred; or
                                </p>
                            </li>
                            <li>
                                <p id="content">
                                   other persons, including government agencies, regulatory bodies and law enforcement agencies, or as required, authorised or permitted by law.
                                </p>
                            </li>
                        </ul>
                        Where we do disclose your personal information, it will be on the basis that these individuals and organisations are required to keep the information confidential and secure, and they will only use the information to carry out the instructed services. 
                    </p>
                    <br /> <br />
                </div>
                <div className="eu-au central">
                    <p id="heading">
                        7. Disclosure of personal information outside Australia and the EU or EEA
                    </p>
                    <p id="content">
                        We may disclose personal information outside of Australia and the EU or EEA (European Economic Area) to the individuals and organisations described above, including third party suppliers and service providers located in the United States.
                       <br /> <br /> 
                        When you provide your personal information to us, you consent to the disclosure of your information outside of Australia and the EEA. While we are not required to ensure that overseas recipients handle that personal information in compliance with Australian privacy law or The European Union General Data Protection Regulations (GDPR), we will take reasonable steps to ensure that any overseas recipient will deal with such personal information in a way that is consistent with the Australian Privacy Principles, the GDPR and this Privacy Policy. We will also use seek to include the EU Commission-approved standard contractual clauses in the form of an appropriate data transfer agreement.
                    </p>
                    <br /> <br />
                </div>                
                <div className="sharing central">
                    <p id="heading">
                        8. How long will we keep your personal information?
                    </p>
                    <p id="content">
                        We will retain your personal information for as long as we consider necessary to provide the relevant services and to maintain business records for tax, legal and regulatory reasons.
                        <br /> <br />
                        In the context of our research relating to sales and ownership of Elementals NFTs, we will keep this data for as long as the record is relevant to our legitimate business interest and the public interest
                    </p>
                    <br /> <br />
                </div>
                <div className="privacy central">
                    <p id="heading">
                        9. Using our website and cookies
                    </p>
                    <p id="content">
                        We do not collect personal information about you when you use and access our website. We do not make use of cookies or other similar tracking devices to store information relating to your visit such as a unique identifier, or a value to indicate where you have seen a webpage. 
                    </p>
                    <br /> <br />
                </div>
                <div className="security central">
                    <p id="heading">
                        10. Security
                    </p>
                    <p id="content">
                        We may hold your personal information in either electronic or hard copy form. We take reasonable steps to protect your personal information from misuse, interference and loss, as well as unauthorised access, modification or disclosure and we use a number of physical, administrative, personnel and technical measures to protect your personal information. 
                        <br /> <br />
                        Notwithstanding these efforts, we cannot guarantee the security of your personal information held in our systems, nor that that information you supply through the internet or any computer network is entirely safe from unauthorised intrusion, access or manipulation during transmission. Any transmission is at your own risk and we will not be liable for any resulting misuse of your personal data.
                    </p>
                    <br /> <br />
                </div>
                <div className="acceptance central">
                    <p id="heading">
                        11. Links to Third Party Websites and Blockchains
                    </p>
                    <p id="content">
                         Our emails and website may contain links to websites operated by third parties. Those links are provided for convenience and may not remain current or be maintained. Unless expressly stated otherwise, we are not responsible for the privacy practices of, or any content on, those linked websites or transactions on the blockchain, and have no control over or rights in those linked websites or transactions on the blockchain. The privacy policies that apply to linked websites may differ substantially from our Privacy Policy, so we encourage you to read them before using those websites.
                    </p>
                </div>
                <div className="children-privacy central">
                    <p id="heading">
                        12. Children's privacy rights
                    </p>
                    <p id="content">
                        Elementals does not knowingly collect any personal information from children under the age of 13. If you think that your child provided this kind of information to us, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.
                    </p>
                    <br /> <br />
                </div>
                <div className="correcting-info central">
                    <p id="heading">
                        13. Accessing or correcting your personal information, erasure and similar rights
                    </p>
                    <p id="content">
                        You can access the personal information we hold about you by contacting us using the contact details below. We may also need to verify your identity when you request your personal information. 
                        <br /> <br />
                        If you think that any personal information we hold about you is inaccurate, please contact us and we will take reasonable steps to ensure that it is corrected. You can also request us to complete information if you believe it is incomplete. 
                        <br /> <br />
                        You can also object to the collecting, use or disclosure of your personal information by us, or withdraw your consent to such processing by us, or request that we erase, restrict or transfer your personal information to another organisation or directly to you. If you would like to make such a request, please contact us using the information below. 
                    </p>
                    <br /> <br />
                </div>
                <div className="erasure central">
                    <p id="heading">
                       14. No ability to correct or erase your personal information from the blockchain 
                    </p>
                    <p id="content">
                         While we can take action on requests in relation to personal information on our website and emails, we have no control over the information recorded on the Avalanche blockchain when an Elementals NFT is minted, traded or transferred. Since each transaction on a blockchain is permanently recorded across its ledger, it cannot be corrected, restricted, transferred or removed from that blockchain. Any recording of your personal information on the blockchain is at your own risk and we will not be liable for our inability to correct, restrict, transfer or remove your personal information from a blockchain. 
                        <br /> <br />
                    </p>
                </div>
                <div className="complaint central">
                    <p id="heading">
                        15. Making a complaint
                    </p>
                    <p id="content">
                        If you wish to make a complaint about the way we have handled your personal information, you can contact us using the details set out below. Please include your name, email address and/or telephone number and clearly describe your complaint. We will acknowledge your complaint and respond to you regarding your complaint within a reasonable period of time. If you think that we have failed to resolve the complaint satisfactorily, we will provide you with information about the further steps you can take. 
                        <br /> <br />
                        You may raise a complaint with the Information Commissioner's Office (ICO) or your local regulator if you consider that we have infringed applicable data privacy laws when processing your personal data. However, we would appreciate the chance to deal with your concerns before you approach the ICO or your local regulator, so please contact us in the first instance.
                    </p>
                        <br /> <br />
                </div>
                <div className="contact-us central">
                    <p id="heading">
                        16. Contact Us
                    </p>
                    <p id="content">
                        For further information about our Privacy Policy or practices, or to access or request to correct your personal information, or make a complaint, please contact our Privacy Officer at  info@elementalsnfts.com
                    </p>
                    <br /> <br />
                    <p id="content">
                        <b>Effective From:</b> 28 June 2023<br />
                        <b>Last Updated:</b> 28 June 2023
                        <br /> <br />
                    </p>
                </div>
                <Link id="link-tag" to={"/"}>
                    <div className="btn">
                        <p id="link">Back to main page</p>
                    </div>
                </Link>
            </div>
        </div>
    );
};

export default Policy;
